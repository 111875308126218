import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// material-ui
import { Box, IconButton, Toolbar } from '@mui/material';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import FilterDrawer from './FilterDrawer';
import Tools from './Tools';
import tools from 'menu-items/twinupworld-tools';

// types
import { openDrawer } from 'store/reducers/menu';

// ==============================|| MAIN LAYOUT ||============================== //

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FiltersLayout = () => {
    const iconBackColor = 'grey.100';
    const iconBackColorOpen = 'grey.200';

    // const auth = useSelector((state) => state.auth.value);
    const [open, setOpen] = React.useState(false);
    const [activeTool, setActiveTool] = React.useState('');

    const [navOpen, setNavOpen] = useState(true);
    const [siteDetailsSaved, setSiteDetailSaved] = useState(false);

    const dispatch = useDispatch();

    function openNav() {
        setNavOpen(true);
    }

    function closeNav() {
        setNavOpen(false);
    }

    function handleNavToggle() {
        if (navOpen) {
            closeNav();
        } else {
            openNav();
        }
    }

    function closeInfo() {
        setOpen(false);
        setActiveTool('');
    }

    function closeSaveStatus() {
        setSiteDetailSaved(false);
    }

    return (
        <React.Fragment>
            <Tools
                id="filterTools"
                tools={tools}
                open={open}
                toggleOpen={() => {
                    // open ? setOpen(false) : setOpen(true);
                }}
                setActiveTool={(newTool) => {
                    console.log(newTool, activeTool);
                    if (newTool === 'AssessSite') {
                        console.log('Assessing site...');
                        fetch('https://arche.thetwinmaster.com/twinworld/assessSite', { credentials: 'include' })
                            .then((data) => data.json())
                            .then((site) => {
                                console.log('site details', site);
                                setSiteDetailSaved(true);
                            });
                    } else if (newTool === 'Legend') {
                        setOpen(true);
                        setActiveTool('Legend');
                    }
                    /*if (newTool === 'Robot') {
                        setTimeout(() => {
                            if (window.scanState === 'scanning') {
                                setOpen(false);
                            } else if (window.scanState === 'complete') {
                                setOpen(true);
                            }
                        }, 3000);
                    }*/
                }}
            />
            <Snackbar
                open={siteDetailsSaved}
                onClose={closeSaveStatus}
                autoHideDuration={1000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert severity="success" sx={{ width: '100%' }}>
                    Site detail saved!
                </Alert>
            </Snackbar>
            <FilterDrawer filterToolsId="filterTools" open={open} activeTool={activeTool} closeInfo={closeInfo} />
        </React.Fragment>
    );
};

export default FiltersLayout;
