import { useEffect, forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button, ButtonBase, Typography, List, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RefreshIcon from '@mui/icons-material/Refresh';
import TableContainer from '@mui/material/TableContainer';
import Scenes from 'components/Scenes';
import SceneTaskExplorer from 'components/SceneTaskExplorer';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import CheckboxTree from 'react-checkbox-tree';
import { styled } from '@mui/material/styles';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import TerrainApp from './TerrainApp';
import RobotTool from '../FiltersLayout/FilterDrawer/RobotTool';
import LegendTool from '../FiltersLayout/FilterDrawer/LegendTool';
import notif from '../../icons/letter_white.png';
import bellIcon from '../../icons/bell-new-notif.png';
import feedIcon from '../../icons/feed.png';
import letterIcon from '../../icons/letter-new-msg.png';
import rotateDial from '../../icons/rotateDial.svg';
import scaleDial from '../../icons/scaleDial.svg';
import DialerKnob from '../../components/DialerKnob';
import SearchOption from './SearchOption';

import { ReactComponent as MoveIconSvg } from '../../icons/LeftToolbar/Move.svg';
import { ReactComponent as RotateIconSvg } from '../../icons/LeftToolbar/Rotate.svg';
import { ReactComponent as ScaleIconSvg } from '../../icons/LeftToolbar/Scale.svg';

import Icon from '@ant-design/icons';
import NorthIcon from '@mui/icons-material/North';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import SouthIcon from '@mui/icons-material/South';
import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import 'font-awesome/css/font-awesome.min.css';

// material-ui
import { Box, IconButton, Toolbar } from '@mui/material';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

// types
import { openDrawer } from 'store/reducers/menu';
import MarqueTool from 'layout/AssessSite/FilterDrawer/MarqueTool';
import SearchTool from 'layout/AssessSite/FilterDrawer/SearchTool';
import WeatherTool from 'layout/AssessSite/FilterDrawer/WeatherTool';
import BuildingMetricsTool from 'layout/AssessSite/FilterDrawer/BuildingMetricsTool';
import { ListItemText } from '../../../node_modules/@mui/material/index';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SvgIcon = (svgComponent, props) => <Icon component={svgComponent} {...props} />;
const icons = {
    MoveIcon: (props) => SvgIcon(MoveIconSvg, props),
    RotateIcon: (props) => SvgIcon(RotateIconSvg, props),
    ScaleIcon: (props) => SvgIcon(ScaleIconSvg, props)
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

// ==============================|| MAIN LAYOUT ||============================== //

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {<Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.element,
    value: PropTypes.number,
    index: PropTypes.number
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    };
}

function createGrid(latitude, longitude, rectangleHalfSize) {
    const gridWidth = 41;
    const gridHeight = 41;
    const featureLatitude = Cesium.Math.toRadians(latitude);
    const featureLongitude = Cesium.Math.toRadians(longitude);
    const e = new Cesium.Rectangle(
        featureLongitude - rectangleHalfSize,
        featureLatitude - rectangleHalfSize,
        featureLongitude + rectangleHalfSize,
        featureLatitude + rectangleHalfSize
    );
    const terrainSamplePositions = [];
    for (let y = 0; y < gridHeight; ++y) {
        for (let x = 0; x < gridWidth; ++x) {
            const longitude = Cesium.Math.lerp(e.west, e.east, x / (gridWidth - 1));
            const latitude = Cesium.Math.lerp(e.south, e.north, y / (gridHeight - 1));
            const position = new Cesium.Cartographic(longitude, latitude);
            terrainSamplePositions.push(position);
        }
    }
    return terrainSamplePositions;
}

const TerrainLayout = () => {
    const theme = useTheme();
    const iconBackColor = 'grey.100';
    const iconBackColorOpen = 'grey.200';

    // const auth = useSelector((state) => state.auth.value);

    const [navOpen, setNavOpen] = useState(false);
    const [allModels, setAllModels] = useState([]);

    const [legendOpen, setLegendOpen] = useState(false);
    const [marqueOpen, setMarqueOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);

    const [weatherToolOpen, setWeatherToolOpen] = useState(false);
    const [buildingMetricsToolOpen, setBuildingMetricsToolOpen] = useState(false);

    const [fileBeingUploaded, setFileBeingUploaded] = useState('');
    const [fileBeingUploadedExt, setFileBeingUploadedExt] = useState('');

    const [notificationPanelOpen, setNotificationPanelOpen] = useState(false);

    const [assetRotationValue, setAssetRotationValue] = useState(0);

    const [assetScaleValue, setAssetScaleValue] = useState(1);

    const [modelUploaded, setModelUploaded] = useState(false);
    const [modelUploadingInProcess, setModelUploadingInProcess] = useState(false);

    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);

    const [nodeList, setNodeList] = useState([]);

    const [alert, setAlert] = useState({
        open: true,
        message: 'World is new'
    });

    const dispatch = useDispatch();

    const handleAssetScaleChange = (event, newValue) => {
        if (typeof newValue === 'number') {
            setAssetScaleValue(newValue);
            if (window.drone && window.drone.model) {
                window.drone.model.scale = newValue;
            }
            viewer.camera.flyTo({
                destination: viewer.scene.camera.position,
                orientation: {
                    heading: viewer.camera.heading - 0.001,
                    pitch: viewer.camera.pitch,
                    roll: viewer.camera.roll
                }
            });
        }
    };

    const handleAssetRotationChange = (event, newValue) => {
        if (typeof newValue === 'number') {
            setAssetRotationValue(newValue);
            if (window.drone && window.drone.orientation && window.drone.model) {
                const rotation = new Cesium.HeadingPitchRoll(
                    Cesium.Math.toRadians(window.drone.orientation ? window.drone.orientation._value.x + newValue : newValue),
                    Cesium.Math.toRadians(0.0),
                    Cesium.Math.toRadians(0.0)
                );
                console.log('Rotating asset to', rotation);
                window.drone.orientation = Cesium.Transforms.headingPitchRollQuaternion(window.originPosition, rotation);
            }
            viewer.camera.flyTo({
                destination: viewer.scene.camera.position,
                orientation: {
                    heading: viewer.camera.heading - 0.001,
                    pitch: viewer.camera.pitch,
                    roll: viewer.camera.roll
                }
            });
        }
    };

    const toggleNotificationDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setNotificationPanelOpen(open);
    };

    useEffect(() => {
        const modelViewer = document.createElement('script');
        modelViewer.src = 'https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js';
        modelViewer.id = 'modelViewer';
        modelViewer.type = 'module';
        document.querySelector('twinworld-app').shadowRoot.appendChild(modelViewer);

        // const cesiumLibrary = document.createElement('script');
        // cesiumLibrary.src = 'https://dtuas2sql0dg4.cloudfront.net/build/cesium/Cesium.js';
        // cesiumLibrary.id = 'teamsSdkLibrary';
        // document.querySelector('twinworld-app').shadowRoot.appendChild(cesiumLibrary);

        const cesiumNavLibrary = document.createElement('script');
        cesiumNavLibrary.src = 'https://dtuas2sql0dg4.cloudfront.net/build/viewerCesiumNavigationMixin.js';
        cesiumNavLibrary.id = 'viewerNavigation';
        document.querySelector('twinworld-app').shadowRoot.appendChild(cesiumNavLibrary);
        cesiumNavLibrary.onload = function () {
            viewer.extend(Cesium.viewerCesiumNavigationMixin, {});
            document
                .querySelectorAll('style')
                .forEach((style) => document.querySelector('twinworld-app').shadowRoot.append(style.cloneNode(true)));
        };

        const cesiumWidgetStyle = document.createElement('link');
        cesiumWidgetStyle.rel = 'stylesheet';
        cesiumWidgetStyle.href = 'https://dtuas2sql0dg4.cloudfront.net/build/utils/Cesium/Widgets/widgets.css';
        document.querySelector('twinworld-app').shadowRoot.appendChild(cesiumWidgetStyle);

        // ----------- | ----------- | ----------- | ----------- //

        setTimeout(() => {
            // cesiumLibrary.onload = () => {

            const cesiumApp = document.createElement('script');
            cesiumApp.src = 'https://dtuas2sql0dg4.cloudfront.net/build/utils/App/App.js';
            cesiumApp.id = 'cesiumApp';
            document.querySelector('twinworld-app').shadowRoot.appendChild(cesiumApp);

            modelViewer.onload = () => {};
            const cesiumAppScript = document.createElement('script');
            cesiumAppScript.src = 'https://dtuas2sql0dg4.cloudfront.net/build/utils/App/AppScript.js';
            cesiumAppScript.id = 'cesiumAppScript';
            document.querySelector('twinworld-app').shadowRoot.appendChild(cesiumAppScript);

            const transformEntityScript = document.createElement('script');
            // transformEntityScript.src = 'http://localhost:3001/utils/App/TransformEntity.js';
            transformEntityScript.src = 'https://dtuas2sql0dg4.cloudfront.net/build/utils/App/TransformEntity.js';
            transformEntityScript.id = 'transformEntityScript';
            document.querySelector('twinworld-app').shadowRoot.appendChild(transformEntityScript);

            const cameraRefEntityScript = document.createElement('script');
            cameraRefEntityScript.src = 'https://dtuas2sql0dg4.cloudfront.net/build/utils/App/AddCamForRef.js';
            cameraRefEntityScript.id = 'camRefEntityScript';
            document.querySelector('twinworld-app').shadowRoot.appendChild(cameraRefEntityScript);

            const speechRecognitionScript = document.createElement('script');
            speechRecognitionScript.src = 'https://dtuas2sql0dg4.cloudfront.net/build/utils/App/SpeechRecognition.js';
            speechRecognitionScript.id = 'speechRecognitionScript';
            document.querySelector('twinworld-app').shadowRoot.appendChild(speechRecognitionScript);

            // const drawRectangleScript = document.createElement('script');
            // drawRectangleScript.src = 'https://dtuas2sql0dg4.cloudfront.net/build/utils/App/DrawRectangle.js';
            // drawRectangleScript.id = 'drawRectangleScript';
            // document.querySelector('twinworld-app').shadowRoot.appendChild(drawRectangleScript);

            const chooseAreaOfInterestScript = document.createElement('script');
            chooseAreaOfInterestScript.src = 'https://dtuas2sql0dg4.cloudfront.net/build/utils/App/ChooseAreaOfInterest.js';
            // chooseAreaOfInterestScript.src = 'https://dtuas2sql0dg4.cloudfront.net/build/utils/App/ChooseAreaOfInterest.js';
            chooseAreaOfInterestScript.id = 'chooseAreaOfInterestScript';
            document.querySelector('twinworld-app').shadowRoot.appendChild(chooseAreaOfInterestScript);

            const screenShotScript = document.createElement('script');
            screenShotScript.src = 'https://html2canvas.hertzen.com/dist/html2canvas.min.js';
            screenShotScript.id = 'screenShotScript';
            `a `;
            document.querySelector('twinworld-app').shadowRoot.appendChild(screenShotScript);

            const searchSceneId =
                window.location.pathname.indexOf('twinworld') >= 0 ? window.location.pathname.split('/').splice(-1)[0] : undefined;
            if (!searchSceneId) {
                if (document.querySelectorAll('[alt="bullet"]').length == 3) {
                    const searchNode = document.querySelectorAll('[alt="bullet"]')[1].parentNode;
                    const weatherNode = document.querySelectorAll('[alt="bullet"]')[1].parentNode.cloneNode(true);
                    searchNode.parentNode.insertBefore(weatherNode, searchNode);

                    weatherNode.querySelector('[alt="bullet"]').src =
                        'https://twinworld-component.s3.amazonaws.com/build/utils/lib/weather.svg';
                    weatherNode.querySelector('[alt="bullet"]').style.transform = 'translateY(2px) scale(1.5)';
                    weatherNode.querySelector('[alt="bullet"]').onclick = function (event) {
                        event.stopPropagation();
                        event.preventDefault();
                        document.querySelector('twinworld-app').shadowRoot.querySelector('#weatherVisualizer').click();
                    };
                    searchNode.style.display = 'none';
                } else {
                    document.querySelectorAll('[alt="bullet"]')[1].parentNode.style.display = 'block';
                    document.querySelectorAll('[alt="bullet"]')[2].parentNode.style.display = 'none';
                }
            } else {
                document.querySelector('twinworld-app').shadowRoot.querySelector('.cesium-geocoder-input').style.display = 'none';
                document.querySelector('twinworld-app').shadowRoot.querySelector('.searchBtn').style.display = 'none';
                document.querySelector('twinworld-app').shadowRoot.querySelector('.robot-scanner-container').style.left = '22px';
            }

            window.preloadWeatherStyles = true;
            document.querySelector('twinworld-app').shadowRoot.querySelector('#weatherVisualizer').click();
            document.querySelector('twinworld-app').shadowRoot.querySelector('#buildingMetricsVisualizer').click();
            document
                .querySelectorAll('style')
                .forEach((style) => document.querySelector('twinworld-app').shadowRoot.append(style.cloneNode(true)));
            document.querySelector('twinworld-app').shadowRoot.querySelector('#weatherVisualizer').click();
            document.querySelector('twinworld-app').shadowRoot.querySelector('#buildingMetricsVisualizer').click();
            window.preloadWeatherStyles = false;

            // document.querySelector('twinworld-app').shadowRoot.querySelector('#search').parentNode.style.display = 'none';
            // };
        }, 100);

        const cesiumAppStyle = document.createElement('link');
        cesiumAppStyle.rel = 'stylesheet';
        // cesiumAppStyle.href = 'https://twinworld-component.s3.amazonaws.com/build/utils/App/AppStyle.css';
        cesiumAppStyle.href = 'https://dtuas2sql0dg4.cloudfront.net/build/utils/App/AppStyle.css';
        document.querySelector('twinworld-app').shadowRoot.appendChild(cesiumAppStyle);

        fetch('https://arche.thetwinmaster.com/twinworld/uploadedAssets', {
            method: 'GET',
            credentials: 'include'
        })
            .then((res) => res.json())
            .then((jsonRes) => {
                setAllModels(jsonRes.assets);
            });

        //openNav();
    }, []);

    useEffect(() => {
        const nodes = () => {
            return [
                {
                    value: 'buildingId',
                    label: `Name : ${window.currentAssetName ? window.currentAssetName.split('/').splice(-1)[0] : ''}`
                },
                {
                    value: 'siteId',
                    label: 'Site',
                    children: [
                        {
                            value: 'boundingBox',
                            label: `BoundingBox : ${
                                window.destBoundingBox
                                    ? JSON.stringify(window.destBoundingBox, null, 2)
                                    : `{west: , south: , east: , north: }`
                            }`
                        },
                        {
                            value: 'latLong',
                            label: `Latitude, Longitude : (${
                                window.destCenter ? (window.destCenter.latitude * 180) / Cesium.Math.PI : ''
                            }, ${window.destCenter ? (window.destCenter.longitude * 180) / Cesium.Math.PI : ''})`
                        }
                    ]
                },
                {
                    value: 'pois',
                    label: `Points of Interests ${window.entityList ? window.entityList.map((entityDetail, index) => entityDetail) : ''}`
                },
                {
                    value: 'aquis',
                    label: 'Air Quality'
                },
                {
                    value: 'traffic',
                    label: 'Traffic'
                }
            ];
        };
        setNodeList(nodes());
        document.querySelectorAll('a.MuiLink-underlineNone').forEach((link, index) => {
            if (index < 2) {
                link.onclick = () => {
                    document.querySelectorAll('[alt="bullet"]')[1].parentNode.style.display = 'none';
                    document.querySelectorAll('[alt="bullet"]')[2].parentNode.style.display = 'block';
                };
            }
        });
        document.querySelector('a.MuiButtonBase-root').onclick = () => {
            document.querySelectorAll('[alt="bullet"]')[1].parentNode.style.display = 'none';
            document.querySelectorAll('[alt="bullet"]')[2].parentNode.style.display = 'block';
        };
    }, []);

    function openNav() {
        document.querySelector('twinworld-app').shadowRoot.getElementById('model-uploader').style.width = '304px';
        document.querySelector('twinworld-app').shadowRoot.getElementById('model-uploader').style.visibility = 'visible';
        document.querySelector('twinworld-app').shadowRoot.getElementById('model-uploader').style.display = 'block';
        // document.querySelector('twinworld-app').shadowRoot.getElementById('cesiumContainer').style.marginLeft = '163px';
        // document.querySelector('twinworld-app').shadowRoot.getElementById('navOpener').style.display = 'none';
        // document.querySelector('twinworld-app').shadowRoot.getElementById('navCloser').style.display = 'grid';
        setNavOpen(true);
    }

    function closeNav() {
        document.querySelector('twinworld-app').shadowRoot.getElementById('model-uploader').style.width = '0';
        document.querySelector('twinworld-app').shadowRoot.getElementById('cesiumContainer').style.marginLeft = '0';
        setNavOpen(false);
    }

    function openLegend() {
        document.querySelector('twinworld-app').shadowRoot.getElementById('legend-tool').style.width = '304px';
        document.querySelector('twinworld-app').shadowRoot.getElementById('legend-tool').style.visibility = 'visible';
        document.querySelector('twinworld-app').shadowRoot.getElementById('legend-tool').style.display = 'block';
        setLegendOpen(true);
    }

    function closeLegend() {
        document.querySelector('twinworld-app').shadowRoot.getElementById('legend-tool').style.width = '0';
        // document.querySelector('twinworld-app').shadowRoot.getElementById('cesiumContainer').style.marginLeft = '0';
        setLegendOpen(false);
    }

    function openMarque() {
        document.querySelector('twinworld-app').shadowRoot.getElementById('marque-tool').style.width = '304px';
        document.querySelector('twinworld-app').shadowRoot.getElementById('marque-tool').style.visibility = 'visible';
        document.querySelector('twinworld-app').shadowRoot.getElementById('marque-tool').style.display = 'block';
        setMarqueOpen(true);
    }

    function closeMarque() {
        document.querySelector('twinworld-app').shadowRoot.getElementById('marque-tool').style.width = '0';
        // document.querySelector('twinworld-app').shadowRoot.getElementById('cesiumContainer').style.marginLeft = '0';
        setMarqueOpen(false);
    }

    function openSearch() {
        document.querySelector('twinworld-app').shadowRoot.getElementById('search-tool').style.width = '304px';
        document.querySelector('twinworld-app').shadowRoot.getElementById('search-tool').style.visibility = 'visible';
        document.querySelector('twinworld-app').shadowRoot.getElementById('search-tool').style.display = 'block';
        setSearchOpen(true);
    }

    function closeSearch() {
        document.querySelector('twinworld-app').shadowRoot.getElementById('search-tool').style.width = '0';
        // document.querySelector('twinworld-app').shadowRoot.getElementById('cesiumContainer').style.marginLeft = '0';
        setSearchOpen(false);
    }

    function openWeatherTool() {
        document.querySelector('twinworld-app').shadowRoot.getElementById('weather-tool').style.visibility = 'visible';
        document.querySelector('twinworld-app').shadowRoot.getElementById('weather-tool').style.width = '304px';
        document.querySelector('twinworld-app').shadowRoot.getElementById('weather-tool').style.display = 'block';
        setWeatherToolOpen(true);
    }

    function closeWeatherTool() {
        document.querySelector('twinworld-app').shadowRoot.getElementById('weather-tool').style.width = '0';
        document.querySelector('twinworld-app').shadowRoot.getElementById('weather-tool').style.visibility = 'hidden';
        // document.querySelector('twinworld-app').shadowRoot.getElementById('cesiumContainer').style.marginLeft = '0';
        setWeatherToolOpen(false);
    }

    function openBuildingMetricsTool() {
        document.querySelector('twinworld-app').shadowRoot.getElementById('building-metrics-tool').style.visibility = 'visible';
        document.querySelector('twinworld-app').shadowRoot.getElementById('building-metrics-tool').style.height = '304px';
        document.querySelector('twinworld-app').shadowRoot.getElementById('building-metrics-tool').style.display = 'block';
        setBuildingMetricsToolOpen(true);
    }

    function closeBuildingMetricsTool() {
        document.querySelector('twinworld-app').shadowRoot.getElementById('building-metrics-tool').style.height = '0';
        document.querySelector('twinworld-app').shadowRoot.getElementById('building-metrics-tool').style.visibility = 'hidden';
        // document.querySelector('twinworld-app').shadowRoot.getElementById('cesiumContainer').style.marginLeft = '0';
        setBuildingMetricsToolOpen(false);
    }

    function handleNavToggle() {
        if (document.querySelector('twinworld-app').shadowRoot.getElementById('model-uploader').style.width != '0px') {
            closeNav();
        } else {
            openNav();
        }
    }

    function handleLegendToggle() {
        if (document.querySelector('twinworld-app').shadowRoot.getElementById('legend-tool').style.width != '0px') {
            closeLegend();
        } else {
            openLegend();
        }
    }

    function handleMarqueToggle() {
        if (document.querySelector('twinworld-app').shadowRoot.getElementById('marque-tool').style.width != '0px') {
            closeMarque();
        } else {
            openMarque();
        }
    }

    function handleSearchToggle() {
        if (document.querySelector('twinworld-app').shadowRoot.getElementById('search-tool').style.width === '304px') {
            closeSearch();
        } else {
            openSearch();
        }
    }

    function handleWeatherVisualizer() {
        if (document.querySelector('twinworld-app').shadowRoot.getElementById('weather-tool').style.width === '304px') {
            closeWeatherTool();
        } else {
            openWeatherTool();
        }
    }

    function handleBuildingMetricsVisualizer() {
        if (document.querySelector('twinworld-app').shadowRoot.getElementById('building-metrics-tool').style.height === '304px') {
            closeBuildingMetricsTool();
        } else {
            openBuildingMetricsTool();
        }
    }

    function allowDrop(ev) {
        ev.preventDefault();
    }

    window.dropNamedAsset = (assetBeingDropped, latLonCoords, destCenterHeight) => {
        if (latLonCoords && destCenterHeight) {
            console.log('Lat lon to drop asset : ', latLonCoords);
            window.destCenter = latLonCoords;
            window.destinationSeaLevelHeight = destCenterHeight;
            destCenter.height = destCenterHeight;
            console.log('Destination center height : ', destCenterHeight);

            const position = {
                lon: (destCenter.longitude * 180) / Cesium.Math.PI,
                lat: (destCenter.latitude * 180) / Cesium.Math.PI,
                radius: 0.01,
                height: destCenterHeight
            };

            dropAssetInTerrain(assetBeingDropped, position, destCenter);
            updateSceneDescription(assetBeingDropped, position);
        } else if (latLonCoords) {
            console.log('Lat lon to drop asset : ', latLonCoords);
            window.destCenter = latLonCoords;
            const destCenterHeight = viewer.scene.globe.getHeight(
                Cesium.Cartographic.fromRadians(destCenter.longitude, destCenter.latitude)
            );
            window.destinationSeaLevelHeight = destCenterHeight;
            destCenter.height = destCenterHeight;
            console.log('Destination center height : ', destCenterHeight);

            const position = {
                lon: (destCenter.longitude * 180) / Cesium.Math.PI,
                lat: (destCenter.latitude * 180) / Cesium.Math.PI,
                radius: 0.01,
                height: destCenterHeight
            };

            dropAssetInTerrain(assetBeingDropped, position, destCenter);
            updateSceneDescription(assetBeingDropped, position);
        } else if (window.destSelectedCenter) {
            console.log('Destination center : ', window.destSelectedCenter);
            window.destCenter = window.destSelectedCenter;
            const destCenterHeight = viewer.scene.globe.getHeight(
                Cesium.Cartographic.fromRadians(destCenter.longitude, destCenter.latitude)
            );
            window.destinationSeaLevelHeight = destCenterHeight;
            destCenter.height = destCenterHeight;
            console.log('Destination center height : ', destCenterHeight);

            const position = {
                lon: (destCenter.longitude * 180) / Cesium.Math.PI,
                lat: (destCenter.latitude * 180) / Cesium.Math.PI,
                radius: 0.01,
                height: destCenterHeight
            };
            dropAssetInTerrain(assetBeingDropped, position, destCenter);
            updateSceneDescription(assetBeingDropped, position);
        } else if (window.destBoundingBox) {
            const destCenter = Cesium.Rectangle.center(window.destBoundingBox);
            console.log('Destination center : ', destCenter);
            window.destCenter = destCenter;
            const destCenterHeight = viewer.scene.globe.getHeight(
                Cesium.Cartographic.fromRadians(destCenter.longitude, destCenter.latitude)
            );
            window.destinationSeaLevelHeight = destCenterHeight;
            destCenter.height = destCenterHeight;
            console.log('Destination center height : ', destCenterHeight);

            const position = {
                lon: (destCenter.longitude * 180) / Cesium.Math.PI,
                lat: (destCenter.latitude * 180) / Cesium.Math.PI,
                radius: 0.01,
                height: destCenterHeight
            };

            dropAssetInTerrain(assetBeingDropped, position, destCenter);
            updateSceneDescription(assetBeingDropped, position);
        } else {
            const position = window.assetDropPosition
                ? window.assetDropPosition
                : {
                      lon: -105.002,
                      lat: 39.73924,
                      radius: 0.01,
                      height: 1577
                  };
            dropAssetInTerrain(assetBeingDropped, position);
            updateSceneDescription(assetBeingDropped, position);
        }
    };

    function drop(ev) {
        ev.preventDefault();
        var assetBeingDropped = ev.dataTransfer.getData('text');
        window.dropNamedAsset(assetBeingDropped);
    }

    function fileSelected() {
        const selectedFileName = document
            .querySelector('twinworld-app')
            .shadowRoot.querySelector('#file')
            .value.replace('C:\\fakepath\\', '');
        setFileBeingUploaded(selectedFileName.replace('.gltf', '').replace('.glb', ''));
        const fileExt = selectedFileName.split('.').pop();
        setFileBeingUploadedExt('.' + fileExt);
    }

    function updateFileName(event) {
        setFileBeingUploaded(event.target.value);
    }

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const setModelData = (e) => {
        console.log('Event data : ', e);
        console.log('Event data : ', e.data);
        setValue(2);
        // document.querySelector('');
    };

    const submenuContainer = {
        display: 'block',
        zIndex: 1201,
        height: '6.5vh',
        maxHeight: '55px',
        marginTop: '1vh',
        marginBottom: '1vh',
        pl: 1.5,
        py: 1,
        '&:hover': {
            bgcolor: 'primary.lighter'
        },
        '&.Mui-selected': {
            bgcolor: 'primary.lighter',
            borderRight: `2px solid primary.main`,
            color: 'primary.main',
            '&:hover': {
                bgcolor: 'primary.lighter'
            }
        },
        '&.Mui-selected': {
            '&:hover': {
                bgcolor: 'transparent'
            },
            bgcolor: 'transparent'
        }
    };

    const checkUploadStatus = (assetId) => {
        fetch(`https://arche.thetwinmaster.com/sim/convert_getStatus/${assetId}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then((res) => res.json())
            .then((resJson) => {
                const conversionStatus = resJson.status;
                const conversionComplete = conversionStatus.toLowerCase().indexOf('completed') >= 0;
                console.log('Conversion Completed ? ', conversionComplete);
                window.conversionComplete = false;
                if (!conversionComplete) {
                    setModelUploaded(false);
                    setTimeout(() => {
                        checkUploadStatus(assetId);
                    }, 500);
                } else {
                    window.conversionComplete = true;
                    setModelUploaded(true);
                    setModelUploadingInProcess(false);
                }
            });
    };

    const uploadModel = () => {
        setModelUploadingInProcess(true);
        window.submitForm().then((assetId) => {
            setTimeout(() => {
                checkUploadStatus(assetId);
            }, 500);
        });
    };

    return (
        <Box id="terrainLayoutContainer" sx={{ display: 'flex', width: '100%' }}>
            {/* <div id="navOpener" role="button" onClick={openNav} tabIndex="0" onKeyDown={openNav} aria-label="open uploader">
                &rArr;
            </div>
            <div id="navCloser" role="button" onClick={closeNav} tabIndex="0" onKeyDown={closeNav} aria-label="close uploader">
                &lArr;
            </div> */}
            <IconButton id="switchModelTab" onClick={setModelData} sx={{ display: 'none' }}></IconButton>
            <IconButton
                id="navChanger"
                disableRipple
                aria-label="open drawer"
                onClick={handleNavToggle}
                edge="start"
                color="secondary"
                sx={{
                    visibility: 'hidden',
                    color: 'text.primary',
                    left: navOpen ? '250px' : '70px',
                    bgcolor: navOpen ? iconBackColorOpen : iconBackColor,
                    ml: { xs: 0, lg: -2 }
                }}
            >
                {!navOpen ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </IconButton>
            <IconButton sx={{ display: 'none' }} id="legendChanger" onClick={handleLegendToggle}></IconButton>
            <IconButton sx={{ display: 'none' }} id="marqueChanger" onClick={handleMarqueToggle}></IconButton>
            <IconButton sx={{ display: 'none' }} id="searchChanger" onClick={handleSearchToggle}></IconButton>
            <IconButton sx={{ display: 'none' }} id="weatherVisualizer" onClick={handleWeatherVisualizer}></IconButton>
            <IconButton sx={{ display: 'none' }} id="buildingMetricsVisualizer" onClick={handleBuildingMetricsVisualizer}></IconButton>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={alert.open}
                // autoHideDuration={3000}
                onClose={() => {
                    setAlert({
                        open: false,
                        message: ''
                    });
                }}
                message={`${alert.message}`}
                key={'top center'}
            >
                <Alert
                    onClose={() => {
                        setAlert({
                            open: false,
                            message: ''
                        });
                    }}
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    {alert.message}
                </Alert>
            </Snackbar>

            <div id="cesiumContainer" onDrop={drop} onDragOver={allowDrop}>
                {/* <Scenes /> */}
                <SceneTaskExplorer modelUploaded={modelUploaded} modelUploadingInProcess={modelUploadingInProcess} />
                <TerrainApp />
            </div>

            <div className="upload-container left-toolbar-container" id="model-uploader">
                <Fab
                    aria-label="close"
                    onClick={() => {
                        document.querySelector('twinworld-app').shadowRoot.querySelector('#navChanger').click();
                    }}
                    color="gray"
                    className="legendClose"
                    sx={{
                        border: 'none !important',
                        boxShadow: 'none'
                    }}
                >
                    <CloseIcon sx={{ color: '#fff !important', transform: 'scale(2) translateY(5.2px)' }} />
                </Fab>
                <br />
                <h1 style={{ fontSize: '20px', marginLeft: '16px', textAlign: 'left', marginTop: '7px' }}>Import</h1>
                <form id="form">
                    <Box sx={{ transform: 'translateY(-20px)' }}>
                        <AppBar
                            position="static"
                            sx={{ backgroundColor: '#363c4f', color: '#fff', boxShadow: 'none', borderBottom: '1px solid #CCC' }}
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                TabIndicatorProps={{ style: { background: '#F36F4B', textColor: '#F36F4B' } }}
                                TabScrollButtonProps={{ color: '#F36F4B' }}
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="model uploader tabs"
                            >
                                <Tab
                                    label="New Model"
                                    sx={
                                        value == 0
                                            ? {
                                                  color: '#F36F4B',
                                                  fontFamily: 'Suisse Intl',
                                                  fontSize: '14px',
                                                  textTransform: 'none',
                                                  fontWeight: 'bold',
                                                  maxWidth: '110px',
                                                  paddingLeft: '0',
                                                  marginLeft: '-2px'
                                              }
                                            : {
                                                  color: '#fff',
                                                  fontFamily: 'Suisse Intl',
                                                  fontSize: '14px',
                                                  textTransform: 'none',
                                                  fontWeight: 'bold',
                                                  maxWidth: '110px',
                                                  paddingLeft: '0',
                                                  marginLeft: '-2px'
                                              }
                                    }
                                    {...a11yProps(0)}
                                />
                                {/* <Tab
                                    label="Library"
                                    sx={
                                        value == 1
                                            ? {
                                                  color: '#F36F4B',
                                                  fontFamily: 'Suisse Intl',
                                                  fontSize: '14px',
                                                  textTransform: 'none',
                                                  fontWeight: 'bold',
                                                  maxWidth: '110px'
                                              }
                                            : {
                                                  color: '#fff',
                                                  fontFamily: 'Suisse Intl',
                                                  fontSize: '14px',
                                                  textTransform: 'none',
                                                  fontWeight: 'bold',
                                                  maxWidth: '110px'
                                              }
                                    }
                                    {...a11yProps(0)}
                                /> */}
                            </Tabs>
                        </AppBar>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel value={value} index={0} dir={theme.direction}>
                                <input id="file" type="file" accept=".glb,.gltf,.rvt" onChange={fileSelected} hidden />
                                {fileBeingUploaded === '' && (
                                    <div>
                                        <Box className="uploadBox">
                                            <div className="input-group">
                                                <label className="file upload-btn" htmlFor="file">
                                                    <button
                                                        className="file3dUploadBtn"
                                                        onClick={() => {
                                                            document
                                                                .querySelector('twinworld-app')
                                                                .shadowRoot.querySelector('#file')
                                                                .click();
                                                        }}
                                                        onKeyDown={() => {
                                                            document
                                                                .querySelector('twinworld-app')
                                                                .shadowRoot.querySelector('#file')
                                                                .click();
                                                        }}
                                                    >
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12} sm={6}>
                                                                <Fab aria-label="add-model" color="gray" className="addImportModel">
                                                                    <AddIcon
                                                                        sx={{
                                                                            color: '#fff !important',
                                                                            transform: 'scale(2)'
                                                                        }}
                                                                    />
                                                                </Fab>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} style={{ paddingTop: '27px', marginLeft: '-5px' }}>
                                                                Browse
                                                            </Grid>
                                                        </Grid>
                                                    </button>
                                                </label>
                                            </div>
                                            {/* <button className="file submit-btn" type="submit">
                                            Upload
                                        </button> */}
                                        </Box>
                                        <Grid container spacing={1} sx={{ marginLeft: 'auto' }}>
                                            <Grid item xs={12} sm={6}>
                                                <ButtonBase
                                                    onClick={() => {
                                                        document
                                                            .querySelector('twinworld-app')
                                                            .shadowRoot.querySelector('#navChanger')
                                                            .click();
                                                        setFileBeingUploaded('');
                                                    }}
                                                    onKeyDown={() => {
                                                        document
                                                            .querySelector('twinworld-app')
                                                            .shadowRoot.querySelector('#navChanger')
                                                            .click();
                                                        setFileBeingUploaded('');
                                                    }}
                                                    sx={{
                                                        padding: '10px',
                                                        border: '1px solid #CCCCCC',
                                                        width: '101px',
                                                        borderRadius: '5px',
                                                        color: '#CCCCCC',
                                                        backgroundColor: '#363c4f',
                                                        transform: 'translateX(-25px)'
                                                    }}
                                                >
                                                    Cancel
                                                </ButtonBase>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <ButtonBase
                                                    onClick={() => {
                                                        document.querySelector('twinworld-app').shadowRoot.querySelector('#file').click();
                                                    }}
                                                    onKeyDown={() => {
                                                        document.querySelector('twinworld-app').shadowRoot.querySelector('#file').click();
                                                    }}
                                                    sx={{
                                                        padding: '10px',
                                                        border: '1px solid #F36F4B',
                                                        width: '101px',
                                                        borderRadius: '5px',
                                                        color: '#fff',
                                                        backgroundColor: '#F36F4B',
                                                        transform: 'translateX(20px)'
                                                    }}
                                                >
                                                    Import
                                                </ButtonBase>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                                {fileBeingUploaded !== '' && (
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            label="Model Name"
                                            variant="outlined"
                                            className="file-upload-name"
                                            sx={{
                                                backgroundColor: '#D9D9D9'
                                            }}
                                            value={fileBeingUploaded}
                                            onChange={updateFileName}
                                            onKeyDown={(e) => {
                                                console.log(e.key);
                                                if (e.key === 'Enter') {
                                                    console.log('Submitting form');
                                                    uploadModel();
                                                    setFileBeingUploaded('');
                                                    document.querySelector('twinworld-app').shadowRoot.querySelector('#navChanger').click();
                                                }
                                            }}
                                        />

                                        <input type="text" className="file-upload-ext" value={fileBeingUploadedExt} hidden />
                                        <div style={{ height: '250px' }}></div>
                                        {/* MODEL AND TEXTURE FILES */}
                                        <Grid container spacing={1} sx={{ marginLeft: 'auto' }}>
                                            <Grid item xs={12} sm={6}>
                                                <ButtonBase
                                                    onClick={() => {
                                                        console.log('Closing form');
                                                        document
                                                            .querySelector('twinworld-app')
                                                            .shadowRoot.querySelector('#navChanger')
                                                            .click();
                                                        setFileBeingUploaded('');
                                                    }}
                                                    onKeyDown={() => {
                                                        console.log('Closing form');
                                                        document
                                                            .querySelector('twinworld-app')
                                                            .shadowRoot.querySelector('#navChanger')
                                                            .click();
                                                        setFileBeingUploaded('');
                                                    }}
                                                    sx={{
                                                        padding: '10px',
                                                        border: '1px solid #CCCCCC',
                                                        width: '101px',
                                                        borderRadius: '5px',
                                                        color: '#CCCCCC',
                                                        backgroundColor: '#363c4f',
                                                        transform: 'translateX(-25px)'
                                                    }}
                                                >
                                                    Cancel
                                                </ButtonBase>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <ButtonBase
                                                    onClick={() => {
                                                        console.log('Submitting form');
                                                        uploadModel();
                                                        setFileBeingUploaded('');
                                                        document
                                                            .querySelector('twinworld-app')
                                                            .shadowRoot.querySelector('#navChanger')
                                                            .click();
                                                    }}
                                                    onKeyDown={() => {
                                                        console.log('Submitting form');
                                                        uploadModel();
                                                        setFileBeingUploaded('');
                                                        document
                                                            .querySelector('twinworld-app')
                                                            .shadowRoot.querySelector('#navChanger')
                                                            .click();
                                                    }}
                                                    sx={{
                                                        padding: '10px',
                                                        border: '1px solid #F36F4B',
                                                        width: '101px',
                                                        borderRadius: '5px',
                                                        color: '#fff',
                                                        backgroundColor: '#F36F4B',
                                                        transform: 'translateX(20px)'
                                                    }}
                                                >
                                                    Import
                                                </ButtonBase>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </TabPanel>
                            {/* <TabPanel value={value} index={1} dir={theme.direction}>
                                <div id="all-models"></div>
                            </TabPanel> */}
                            <TabPanel value={value} index={2} dir={theme.direction}>
                                <div id="model-details">
                                    <h3>{window.currModel && window.currModel.replace('./models/', '')}</h3>
                                    <model-viewer src={`${window.currModel}`} ar shadow-intensity="1" camera-controls touch-action="pan-y">
                                        <div className="annotation"></div>{' '}
                                    </model-viewer>
                                    <h4 style={{ color: '#fff', marginBottom: '5px' }}>LOCATION</h4>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                value={
                                                    window.currModel
                                                        ? document
                                                              .querySelector('twinworld-app')
                                                              .shadowRoot.getElementById(window.currentAssetName + '_lat').value
                                                        : ''
                                                }
                                                label="Latitude"
                                                variant="standard"
                                                id="modelLatitude"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                value={
                                                    window.currModel
                                                        ? document
                                                              .querySelector('twinworld-app')
                                                              .shadowRoot.getElementById(window.currentAssetName + '_lon').value
                                                        : ''
                                                }
                                                label="Longitude"
                                                variant="standard"
                                                id="modelLongitude"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField label="Rotation" variant="standard" id="modelRotation" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                value={
                                                    window.currModel
                                                        ? document
                                                              .querySelector('twinworld-app')
                                                              .shadowRoot.getElementById(window.currentAssetName + '_height').value
                                                        : ''
                                                }
                                                label="Elevation"
                                                variant="standard"
                                                id="modelElevation"
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </TabPanel>
                        </SwipeableViews>
                    </Box>

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}></Grid>
                        <Grid item xs={12} sm={12}></Grid>
                    </Grid>
                </form>
            </div>
            <div className="scene-creation-container left-toolbar-container" id="scene-creator">
                <h1 style={{ fontSize: '20px', marginLeft: '15px' }}>Scene Editor</h1>
                <form id="form">
                    <Box>
                        <AppBar position="static" sx={{ backgroundColor: '#161c1c', color: '#fff' }}></AppBar>
                        <Grid item xs={12} sm={12} sx={{ transform: 'translateX(15px)' }}>
                            Scene details
                        </Grid>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <CheckboxTree
                                iconsClass="fa4"
                                nodes={nodeList}
                                checked={checked}
                                expanded={expanded}
                                onCheck={(checked) => setChecked(checked)}
                                onExpand={(expanded) => setExpanded(expanded)}
                                icons={{
                                    check: <span className="rct-icon rct-icon-check" />,
                                    uncheck: <span className="rct-icon rct-icon-uncheck" />,
                                    halfCheck: <span className="rct-icon rct-icon-half-check" />,
                                    expandClose: <span className="rct-icon rct-icon-expand-close" />,
                                    expandOpen: <span className="rct-icon rct-icon-expand-open" />,
                                    expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                    collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                                    parentClose: <span className="rct-icon rct-icon-parent-close" />,
                                    parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                                    leaf: <span className="rct-icon rct-icon-leaf" />
                                }}
                            />
                        </Box>
                        <br />
                        <br />
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <ButtonBase
                                onClick={() => {
                                    const dataValue =
                                        window.sceneCreateOrUpdate === 'create'
                                            ? JSON.parse(window.taskToUse)
                                            : JSON.parse(window.sceneToUpdate);
                                    //This encounters for out of sync variable naming in TwinupEng1 database and the response from task list
                                    if (dataValue.building_id && !dataValue.buildingid) {
                                        dataValue.buildingid = dataValue.building_id;
                                    }
                                    window.saveScene(
                                        {
                                            buildingid: dataValue.buildingid,
                                            siteid: dataValue.siteid,
                                            scene_id: dataValue.scene_id,
                                            assetName: window.currentAssetName,
                                            boundingBox: window.destBoundingBox,
                                            latitude: (window.destCenter.latitude * 180) / Cesium.Math.PI,
                                            height: window.destCenter.height,
                                            longitude: (window.destCenter.longitude * 180) / Cesium.Math.PI,
                                            pois: JSON.stringify(window.entityList),
                                            airQuality: JSON.stringify(window.airQuality)
                                        },
                                        window.sceneCreateOrUpdate === 'create'
                                    );
                                }}
                                onKeyDown={() => {
                                    /*
                                    window.saveScene(
                                        {
                                            buildingid: dataValue.buildingid,
                                            siteid: dataValue.siteid,
                                            scene_id: dataValue.scene_id,
                                            property_ids: dataValue.property_ids,
                                            assetName: window.currentAssetName,
                                            boundingBox: window.destBoundingBox,
                                            latitude: (window.destCenter.latitude * 180) / Cesium.Math.PI,
                                            height: window.destCenter.height,
                                            longitude: (window.destCenter.longitude * 180) / Cesium.Math.PI,
                                            pois: JSON.stringify(window.entityList),
                                            airQuality: JSON.stringify(window.airQuality)
                                        },
                                        window.sceneCreateOrUpdate === 'create'
                                    );
                                    */
                                }}
                                sx={{
                                    padding: '10px',
                                    border: '1px solid #F36F4B',
                                    width: '101px',
                                    borderRadius: '5px',
                                    color: '#fff',
                                    backgroundColor: '#F36F4B',
                                    boxShadow: '5px 5px rgb(40 40 40 / 25%)'
                                }}
                            >
                                Save
                            </ButtonBase>
                        </Box>
                    </Box>

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}></Grid>
                        <Grid item xs={12} sm={12}></Grid>
                    </Grid>
                </form>
            </div>

            <div className="robot-scanner-container left-toolbar-container" id="robot-scanner">
                <form id="form">
                    <Box>
                        <AppBar position="static" sx={{ backgroundColor: '#161c1c', color: '#fff' }}></AppBar>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <RobotTool
                                closeRobot={() => {
                                    document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.visibility =
                                        'hidden';
                                    setTimeout(() => {
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.display =
                                            'none';
                                    }, 200);
                                }}
                            />
                        </Box>
                    </Box>

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}></Grid>
                        <Grid item xs={12} sm={12}></Grid>
                    </Grid>
                </form>
            </div>

            <div className="transform-submenu left-toolbar-container" id="transform-submenu">
                <h1 style={{ fontSize: '20px', marginLeft: '15px', marginTop: '5px' }}>Transform</h1>
                <Fab
                    aria-label="close"
                    onClick={() => {
                        window.activeOption = '';
                        document.querySelector('twinworld-app').shadowRoot.querySelector('#transform-submenu').style.display = 'none';
                    }}
                    color="gray"
                    className="legendClose"
                    sx={{
                        border: 'none !important',
                        boxShadow: 'none',
                        transform: 'scale(0.6) translateY(-24px)'
                    }}
                >
                    <CloseIcon sx={{ color: '#fff !important', transform: 'scale(2) translateY(5.2px)' }} />
                </Fab>
                <h3 style={{ fontSize: '15px', marginLeft: '15px' }}>Select from the following Transforming options</h3>
                <form id="form">
                    <List>
                        <ListItem sx={submenuContainer}>
                            <ListItemButton
                                onClick={() => {
                                    document.querySelector('twinworld-app').shadowRoot.querySelector('#scale-container').style.display =
                                        'none';
                                    document.querySelector('twinworld-app').shadowRoot.querySelector('#rotate-container').style.display =
                                        'none';
                                    if (
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('#move-container').style
                                            .display === '' ||
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('#move-container').style
                                            .display === 'none'
                                    ) {
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('#move-container').style.display =
                                            'block';
                                        document
                                            .querySelector('twinworld-app')
                                            .shadowRoot.querySelector('#move-container').style.visibility = 'visible';
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('#assetLocation').innerHTML = '';
                                        if (window.lastDestCarto) {
                                            window.writeAssetLocation(
                                                document.querySelector('twinworld-app').shadowRoot.querySelector('#assetLocation'),
                                                window.currentAssetName,
                                                {
                                                    lat: window.lastDestCarto.latitude,
                                                    lon: window.lastDestCarto.longitude
                                                }
                                            );
                                        }
                                    } else {
                                        document
                                            .querySelector('twinworld-app')
                                            .shadowRoot.querySelector('#move-container').style.visibility = 'hidden';
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('#move-container').style.display =
                                            'none';
                                    }
                                }}
                            >
                                <Grid
                                    sx={{
                                        transform: 'translateY(-7px)',
                                        height: '24px'
                                    }}
                                    container
                                    spacing={1}
                                >
                                    <Grid item xs={12} sm={3} sx={{ textAlign: 'left', fontSize: '1.5rem' }}>
                                        <ListItemIcon>{icons.MoveIcon()}</ListItemIcon>
                                    </Grid>
                                    <Grid item xs={12} sm={5} sx={{ textAlign: 'left', lineHeight: '1.5rem !importanrt' }}>
                                        <ListItemText>
                                            <Typography sx={{ fontSize: '1rem', lineHeight: '1.5rem !importanrt' }}>Move</Typography>
                                        </ListItemText>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                        <ListItem sx={submenuContainer}>
                            <ListItemButton
                                onClick={() => {
                                    document.querySelector('twinworld-app').shadowRoot.querySelector('#move-container').style.display =
                                        'none';
                                    document.querySelector('twinworld-app').shadowRoot.querySelector('#scale-container').style.display =
                                        'none';
                                    if (
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('#rotate-container').style
                                            .display === '' ||
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('#rotate-container').style
                                            .display === 'none'
                                    ) {
                                        document
                                            .querySelector('twinworld-app')
                                            .shadowRoot.querySelector('#rotate-container').style.display = 'block';
                                        document
                                            .querySelector('twinworld-app')
                                            .shadowRoot.querySelector('#rotate-container').style.visibility = 'visible';
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('#assetLocation').innerHTML = '';
                                        if (window.lastDestCarto) {
                                            window.writeAssetLocation(
                                                document.querySelector('twinworld-app').shadowRoot.querySelector('#assetLocation'),
                                                window.currentAssetName,
                                                {
                                                    lat: window.lastDestCarto.latitude,
                                                    lon: window.lastDestCarto.longitude
                                                }
                                            );
                                        }
                                    } else {
                                        document
                                            .querySelector('twinworld-app')
                                            .shadowRoot.querySelector('#rotate-container').style.visibility = 'hidden';
                                        document
                                            .querySelector('twinworld-app')
                                            .shadowRoot.querySelector('#rotate-container').style.display = 'none';
                                    }
                                }}
                            >
                                <Grid
                                    sx={{
                                        transform: 'translateY(-7px)',
                                        height: '24px'
                                    }}
                                    container
                                    spacing={1}
                                >
                                    <Grid item xs={12} sm={3} sx={{ textAlign: 'left', fontSize: '1.5rem' }}>
                                        <ListItemIcon>{icons.RotateIcon()}</ListItemIcon>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={5}
                                        sx={{ textAlign: 'left', fontSize: '1rem', lineHeight: '1.5rem !importanrt' }}
                                    >
                                        <ListItemText>
                                            <Typography sx={{ fontSize: '1rem', lineHeight: '1.5rem !importanrt' }}>Rotate</Typography>
                                        </ListItemText>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                        <ListItem sx={submenuContainer}>
                            <ListItemButton
                                onClick={() => {
                                    document.querySelector('twinworld-app').shadowRoot.querySelector('#move-container').style.display =
                                        'none';
                                    document.querySelector('twinworld-app').shadowRoot.querySelector('#rotate-container').style.display =
                                        'none';
                                    if (
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('#scale-container').style
                                            .display === '' ||
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('#scale-container').style
                                            .display === 'none'
                                    ) {
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('#scale-container').style.display =
                                            'block';
                                        document
                                            .querySelector('twinworld-app')
                                            .shadowRoot.querySelector('#scale-container').style.visibility = 'visible';
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('#assetLocation').innerHTML = '';
                                        if (window.lastDestCarto) {
                                            window.writeAssetLocation(
                                                document.querySelector('twinworld-app').shadowRoot.querySelector('#assetLocation'),
                                                window.currentAssetName,
                                                {
                                                    lat: window.lastDestCarto.latitude,
                                                    lon: window.lastDestCarto.longitude
                                                }
                                            );
                                        }
                                    } else {
                                        document
                                            .querySelector('twinworld-app')
                                            .shadowRoot.querySelector('#scale-container').style.visibility = 'hidden';
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('#scale-container').style.display =
                                            'none';
                                    }
                                }}
                            >
                                <Grid
                                    sx={{
                                        transform: 'translateY(-7px)',
                                        height: '24px'
                                    }}
                                    container
                                    spacing={1}
                                >
                                    <Grid item xs={12} sm={3} sx={{ textAlign: 'left', fontSize: '1.5rem' }}>
                                        <ListItemIcon>{icons.ScaleIcon()}</ListItemIcon>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={5}
                                        sx={{ textAlign: 'left', fontSize: '1rem', lineHeight: '1.5rem !importanrt' }}
                                    >
                                        <ListItemText>
                                            <Typography sx={{ fontSize: '1rem', lineHeight: '1.5rem !importanrt' }}>Scale</Typography>
                                        </ListItemText>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}></Grid>
                        <Grid item xs={12} sm={12}></Grid>
                    </Grid>
                    <br />
                </form>
            </div>
            <div className="option-search search-submenu" id="search-submenu">
                <SearchOption placeholder="Search apartments/hospitals/.. in selected area..."></SearchOption>
            </div>
            <div className="move-container left-toolbar-container" id="move-container">
                <h1 style={{ fontSize: '20px', marginLeft: '15px' }}>Move asset</h1>
                <h3 style={{ fontSize: '15px', marginLeft: '15px' }}>
                    Move asset by clicking on the controller or altering latitude, longitude values
                </h3>
                <form id="form">
                    <Box>
                        <AppBar position="static" sx={{ backgroundColor: '#161c1c', color: '#fff' }}></AppBar>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}></Box>
                    </Box>

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} sx={{ textAlign: 'center' }}>
                            <IconButton id="moveAssetUp" sx={{ color: '#fff' }} variant="outlined" onClick={() => {}}>
                                <NorthIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
                            <IconButton id="moveAssetLeft" sx={{ color: '#fff' }} variant="outlined" onClick={() => {}}>
                                <WestIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
                            <IconButton id="moveAssetRight" sx={{ color: '#fff' }} variant="outlined" onClick={() => {}}>
                                <EastIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} sx={{ textAlign: 'center' }}>
                            <IconButton id="moveAssetDown" sx={{ color: '#fff' }} variant="outlined" onClick={() => {}}>
                                <SouthIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}></Grid>
                        <Grid item xs={12} sm={12}></Grid>
                    </Grid>
                    <br />
                </form>
                <br />
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <div id="assetLocation" style={{ width: '160px', margin: 'auto' }}></div>
                </Box>
            </div>
            <div className="rotate-container left-toolbar-container" id="rotate-container">
                <h1 style={{ fontSize: '20px', marginLeft: '15px' }}>Rotate asset</h1>
                <h3 style={{ fontSize: '15px', marginLeft: '15px' }}>
                    Rotate asset by clicking on the controller or altering latitude, longitude values
                </h3>
                <form id="form">
                    <Box>
                        <AppBar position="static" sx={{ backgroundColor: '#161c1c', color: '#fff' }}></AppBar>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}></Box>
                    </Box>

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} sx={{ textAlign: 'center' }}>
                            <DialerKnob
                                size={100}
                                numTicks={24}
                                degrees={360}
                                min={1}
                                max={100}
                                value={assetRotationValue}
                                color={true}
                                onChange={(value) => {
                                    handleAssetRotationChange(null, value);
                                }}
                            />
                            <br />
                            <Typography id="scale-slider" gutterBottom>
                                <TextField
                                    id="asset-rotation-value"
                                    label="Rotate"
                                    variant="standard"
                                    value={assetRotationValue}
                                    sx={{
                                        color: '#fff'
                                    }}
                                    onChange={(event) => {
                                        try {
                                            if (isNaN(parseFloat(event.target.value))) {
                                                setAssetRotationValue(0);
                                            } else {
                                                if (!event.target.value.endsWith('.')) {
                                                    setAssetRotationValue(parseFloat(event.target.value));
                                                    handleAssetRotationChange(null, parseFloat(event.target.value));
                                                } else {
                                                    setAssetRotationValue(event.target.value);
                                                }
                                            }
                                        } catch (err) {
                                            setAssetRotationValue(0);
                                        }
                                    }}
                                    onKeyPress={(event) => {
                                        if (event.charCode == 13) {
                                            event.stopPropagation();
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </Typography>
                            {/* <Slider
                                value={assetRotationValue}
                                defaultValue={1}
                                min={0.1}
                                step={0.01}
                                marks
                                max={2}
                                onChange={handleAssetRotationChange}
                                valueLabelDisplay="auto"
                                aria-labelledby="scale-slider"
                            /> */}
                        </Grid>
                    </Grid>
                    <br />
                </form>
                <br />
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <div id="assetLocation" style={{ width: '160px', margin: 'auto' }}></div>
                </Box>
            </div>

            <div className="scale-container left-toolbar-container" id="scale-container">
                <h1 style={{ fontSize: '20px', marginLeft: '15px' }}>Scale asset</h1>
                <h3 style={{ fontSize: '15px', marginLeft: '15px' }}>Scale asset by using the slider</h3>
                <form id="form">
                    <Box>
                        <AppBar position="static" sx={{ backgroundColor: '#161c1c', color: '#fff' }}></AppBar>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}></Box>
                    </Box>

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} sx={{ textAlign: 'center' }}>
                            <DialerKnob
                                size={100}
                                numTicks={14}
                                degrees={180}
                                min={1}
                                max={100}
                                value={assetScaleValue}
                                color={true}
                                hideNumbers={true}
                                valueParser={(val) => val / 18}
                                onChange={(value) => {
                                    handleAssetScaleChange(null, parseFloat(Math.abs(value / 18).toFixed(2)));
                                }}
                            />
                            <br />
                            <Typography id="scale-slider" gutterBottom>
                                <TextField
                                    id="asset-scale-value"
                                    label="Scale factor"
                                    variant="standard"
                                    value={assetScaleValue}
                                    sx={{
                                        color: '#fff'
                                    }}
                                    onChange={(event) => {
                                        try {
                                            if (isNaN(parseFloat(event.target.value))) {
                                                setAssetScaleValue(1);
                                            } else {
                                                if (!event.target.value.endsWith('.')) {
                                                    const changedVal = parseFloat(event.target.value);
                                                    const finalVal = changedVal > 10 ? 10 : changedVal;
                                                    setAssetScaleValue(finalVal);
                                                    handleAssetScaleChange(null, Math.abs(changedVal));
                                                } else {
                                                    setAssetScaleValue(event.target.value);
                                                }
                                            }
                                        } catch (err) {
                                            setAssetScaleValue(1);
                                        }
                                    }}
                                    onKeyPress={(event) => {
                                        if (event.charCode == 13) {
                                            event.stopPropagation();
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </Typography>
                            {/* <Slider
                                value={assetScaleValue}
                                defaultValue={1}
                                min={0.1}
                                step={0.01}
                                marks
                                max={2}
                                onChange={handleAssetScaleChange}
                                valueLabelDisplay="auto"
                                aria-labelledby="scale-slider"
                            /> */}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}></Grid>
                        <Grid item xs={12} sm={12}></Grid>
                    </Grid>
                    <br />
                </form>
                <br />
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <div id="assetLocation" style={{ width: '160px', margin: 'auto' }}></div>
                </Box>
            </div>

            <div className="legend-container left-toolbar-container" id="legend-container">
                <form id="form">
                    <Box>
                        <AppBar position="static" sx={{ backgroundColor: '#161c1c', color: '#fff' }}></AppBar>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <LegendTool
                                closeLegend={() => {
                                    document.querySelector('twinworld-app').shadowRoot.querySelector('#legend-container').style.visibility =
                                        'hidden';
                                    setTimeout(() => {
                                        document
                                            .querySelector('twinworld-app')
                                            .shadowRoot.querySelector('#legend-container').style.display = 'none';
                                    }, 200);
                                }}
                            />
                        </Box>
                    </Box>

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}></Grid>
                        <Grid item xs={12} sm={12}></Grid>
                    </Grid>
                </form>
            </div>

            <div id="legend-tool" className="left-toolbar-container">
                {legendOpen && <LegendTool closeLegend={closeLegend} />}
            </div>
            <div id="marque-tool" className="left-toolbar-container">
                {marqueOpen && <MarqueTool closeMarque={closeMarque} />}
            </div>
            <div id="search-tool" className="left-toolbar-container">
                {searchOpen && <SearchTool closeSearch={closeSearch} />}
            </div>
            <div id="weather-tool" className="left-toolbar-container">
                {weatherToolOpen && <WeatherTool closeWeatherTool={closeWeatherTool} latitude={23.75} longitude={72.5} />}
            </div>
            <div id="building-metrics-tool" className="topup-toolbar-container">
                {buildingMetricsToolOpen && <BuildingMetricsTool closeWeatherTool={closeWeatherTool} latitude={23.75} longitude={72.5} />}
            </div>
            <div className="loaderbg">
                <div className="loader"></div>
            </div>
            {/* <Button variant="text" className="notificationPanel" onClick={toggleNotificationDrawer(true)}>
                <img style={{ width: '20px', height: '20px' }} alt="Notif" src={notif} />
            </Button> */}
            <SwipeableDrawer
                sx={{ width: 0 }}
                id="notifPanel"
                anchor={'right'}
                open={notificationPanelOpen}
                onClose={toggleNotificationDrawer(false)}
                onOpen={toggleNotificationDrawer(true)}
            >
                <Grid container spacing={2} className="notifGrid">
                    <Grid item xs={3} sm={3}></Grid>
                    <Grid item xs={3} sm={3}>
                        <img style={{ width: '20px', height: '20px' }} alt="Notif" src={bellIcon} />
                    </Grid>
                    <Grid item xs={3} sm={3}>
                        <img style={{ width: '20px', height: '20px' }} alt="Notif" src={notif} />
                    </Grid>
                    <Grid item xs={3} sm={3}>
                        <img style={{ width: '20px', height: '20px' }} alt="Notif" src={letterIcon} />
                    </Grid>
                    <Grid item xs={3} sm={3}></Grid>
                    <Grid item xs={3} sm={3}></Grid>
                </Grid>
            </SwipeableDrawer>
        </Box>
    );
};

export default TerrainLayout;
