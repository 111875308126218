import React, { useCallback, useRef, useState } from 'react';
import { Box, ButtonBase, ClickAwayListener, Grid, Popper, Stack } from '@mui/material';
// import { useAppSelector } from 'hooks';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Cookies from 'js-cookie';
import Transitions from '../../../../components/Transitions';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Avatar from '../../../../components/Avatar';

function deleteCookie() {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        if (name === 'twinup') {
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.cityzenith.com';
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        }
    }
}

const Menu = () => {
    const me = useSelector((state) => {
        if (state.user && state.user.me) {
            return state.user.me;
        }
    });
    const navigate = useNavigate();

    const handleLogout = useCallback(() => {
        axios
            .get(`/user/logout`)
            .then((response) => {
                deleteCookie();
                navigate('/');
            })
            .catch((error) => {
                console.log(error);
            });
    }, [navigate]);

    const anchorRef = useRef(null);
    const [popperOpen, setPopperOpen] = useState(false);
    const handlePopperToggle = useCallback(() => {
        setPopperOpen((prevOpen) => !prevOpen);
    }, []);

    const handlePopperClose = (event) => {
        // @ts-ignore
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setPopperOpen(false);
    };
    const cookie = Cookies.get('arche-twinup');
    return (
        <>
            <Box>
                <ButtonBase ref={anchorRef} onClick={handlePopperToggle} sx={{ margin: 0 }}>
                    {!popperOpen && <KeyboardArrowDown sx={{ color: '#CCCCCC', fontSize: 18 }} />}
                    {popperOpen && <KeyboardArrowUp sx={{ color: '#CCCCCC', fontSize: 18 }} />}
                    <Stack direction="row" spacing={2} alignItems="center" sx={{ margin: 0, paddingRight: 1 }}>
                        {cookie && <Avatar user={me} size={30} disableNavigate={true} />}
                    </Stack>
                </ButtonBase>
                <Popper
                    placement="bottom-end"
                    open={popperOpen}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    sx={{ borderRadius: '10px 0 10px 10px', width: '200px' }}
                    popperOptions={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 4]
                                }
                            }
                        ]
                    }}
                >
                    {({ TransitionProps }) => (
                        <Transitions type="fade" in={popperOpen} {...TransitionProps}>
                            {popperOpen && (
                                <ClickAwayListener onClickAway={handlePopperClose}>
                                    <Grid
                                        container
                                        spacing={0}
                                        justifyItems={'left'}
                                        justifyContent={'left'}
                                        sx={{
                                            borderRadius: '10px 0 10px 10px',
                                            padding: '10px',
                                            backgroundColor: '#FFFFFF',
                                            boxShadow: '0px 2px 25px rgba(0, 0, 0, 0.1)'
                                        }}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            onClick={() => {
                                                setPopperOpen(false);
                                                window.location.pathname = '/profile';
                                            }}
                                            sx={{
                                                cursor: 'pointer',
                                                color: '#1E1D30',
                                                borderRadius: '10px',
                                                padding: '8px',
                                                '&:hover': {
                                                    backgroundColor: '#FEF1ED'
                                                }
                                            }}
                                        >
                                            View Profile
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box
                                                sx={{
                                                    height: '2px',
                                                    border: 'none',
                                                    backgroundColor: 'lightgray'
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            onClick={handleLogout}
                                            sx={{
                                                cursor: 'pointer',
                                                color: '#1E1D30',
                                                borderRadius: '10px',
                                                padding: '8px',
                                                '&:hover': {
                                                    backgroundColor: '#FEF1ED'
                                                }
                                            }}
                                        >
                                            <Box sx={{ display: 'flex' }}>
                                                <Box sx={{ width: '50%' }}>Log Out</Box>
                                                <Box
                                                    sx={{
                                                        alignItems: 'right',
                                                        justifyContent: 'right',
                                                        display: 'flex',
                                                        width: '50%'
                                                    }}
                                                >
                                                    <img width={20} src={require('../../../../images/logout.svg').default} alt="bullet" />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </ClickAwayListener>
                            )}
                        </Transitions>
                    )}
                </Popper>
            </Box>
        </>
    );
};

export default Menu;
