import React, { useEffect, useRef, useState, useContext } from 'react';
import { Viewer as ViewerResium, Scene, Primitive, Cesium3DTileset, Globe } from 'resium';
import { Ion, IonResource, CesiumTerrainProvider, createWorldTerrain, createOsmBuildings } from 'cesium';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Basic2d3dSwitch from 'components/Basic2d3dSwitch';

Ion.defaultAccessToken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI5YjdmZGI4MC1hNDI2LTQ4NWYtODMyZS1lZGM3NTVlZjMzMDciLCJpZCI6MTE0MDMwLCJpYXQiOjE2NjgzOTg3ODF9.-in98k0Qp44mvZVsxYmNV_Dvk-T9L0gZkenNq9Ex7Qg';

Cesium.GoogleMaps.defaultApiKey = 'AIzaSyDY52pKLyOv-dTRuSWy_R3Y1uyyCCFUxQ8';
const options = {
    defaultAccessToken:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI5YjdmZGI4MC1hNDI2LTQ4NWYtODMyZS1lZGM3NTVlZjMzMDciLCJpZCI6MTE0MDMwLCJpYXQiOjE2NjgzOTg3ODF9.-in98k0Qp44mvZVsxYmNV_Dvk-T9L0gZkenNq9Ex7Qg'
};
const ionTerrainProvider = IonResource.fromAssetId(1);
const terrainProvider = new CesiumTerrainProvider({ url: ionTerrainProvider, requestWaterMask: true, requestVertexNormals: true });

function App() {
    const [isLoading, setLoading] = useState(true);
    const [show3dMap, setShow3dMap] = useState(false);
    const ref = React.createRef();

    useEffect(() => {
        if (ref.current && ref.current.cesiumElement) {
            let viewer = ref.current.cesiumElement;
            window.viewer = viewer;

            /*
            window.viewer.scene.imageryLayers.add(
                new Cesium.ImageryLayer(
                    new Cesium.OpenStreetMapImageryProvider({
                        url: 'http://tile.openweathermap.org/map/temp_new',
                        fileExtension: 'png' + '?area=worldwide&appid=811aec9d921ed18fcb7555a663efa13e'
                    })
                )
            );
            */

            /*
            window.buildingsTileset = viewer.scene.primitives.add(createOsmBuildings());
            buildingsTileset.style = new Cesium.Cesium3DTileStyle({});

            if (window.viewer.scene.primitives._primitives.length <= 1) {
                Cesium.createGooglePhotorealistic3DTileset(Cesium.GoogleMaps.defaultApiKey, {
                    imageryProvider: false,
                    baseLayerPicker: false,
                    requestRenderMode: true,
                    showCreditsOnScreen: true
                }).then((tileset) => {
                    if (window.viewer.scene.primitives._primitives.length <= 1) {
                        window.viewer.scene.primitives.add(tileset);
                    }
                });
            }
            */

            /*
            buildingsTileset.style = new Cesium.Cesium3DTileStyle({
                color: {
                    conditions: [
                        ["${feature['building']} === 'apartments' || ${feature['building']} === 'residential'", "color('cyan')"],
                        ["${feature['building']} === 'hospital'", "color('rgb(255, 115, 118)')"],
                        ["${feature['building']} === 'commercial'", "color('yellow')"],
                        ["${feature['building']} === 'school'", "color('green')"],
                        [true, "color('white')"]
                    ]
                }
            });
            */
            // viewer._container.firstChild.children[1].style.display = 'none';
            window.viewer = viewer;
            Cesium.createGooglePhotorealistic3DTileset(Cesium.GoogleMaps.defaultApiKey, {
                imageryProvider: false,
                baseLayerPicker: false,
                requestRenderMode: true,
                showCreditsOnScreen: true
            })
                .then((tileset) => {
                    const googlePhotoRealisticTiles = tileset;
                    window.googlePhotoRealisticTileset = window.viewer.scene.primitives.add(googlePhotoRealisticTiles);
                    window.buildingsTileset = viewer.scene.primitives.add(
                        new Cesium.Cesium3DTileset({
                            url: 'https://tile.googleapis.com/v1/3dtiles/root.json?key=AIzaSyDY52pKLyOv-dTRuSWy_R3Y1uyyCCFUxQ8',
                            // This property is needed to appropriately display attributions
                            // as required.
                            showCreditsOnScreen: true
                        })
                    );
                })
                .catch((err) => {
                    viewer.scene.primitives.add(Cesium.createOsmBuildings());
                });

            setTimeout(() => {
                if (window.runCesiumApp) {
                    window.runCesiumApp();
                    if (show3dMap) {
                        // setTimeout(() => {
                        // document.querySelector('twinworld-app').shadowRoot.querySelectorAll('.cesium-baseLayerPicker-item')[0].click();
                        // window.googlePhotoRealisticTileset = window.viewer.scene.primitives.add(window.googlePhotoRealisticTiles);
                        // window.buildingsTileset = window.viewer.scene.primitives.add(Cesium.createOsmBuildings());
                        // }, 1000);
                        // viewer.scene.mode = Cesium.SceneMode.SCENE3D;
                        if (viewer.scene.camera.position) {
                            viewer.camera.flyTo({
                                destination: viewer.scene.camera.position,
                                orientation: {
                                    heading: Cesium.Math.toRadians(20.0),
                                    pitch: Cesium.Math.toRadians(-35.0),
                                    roll: 0.0
                                }
                            });
                        }
                    } else {
                        // document.querySelector('twinworld-app').shadowRoot.querySelectorAll('.cesium-baseLayerPicker-item')[2].click();
                        setTimeout(() => {
                            // var wms = new Cesium.UrlTemplateImageryProvider({
                            //     url: 'https://api.tomtom.com/traffic/map/4/tile/flow/relative0/{z}/{x}/{y}.png?tileSize=512&key=UQL3Re4IVrz6z90ZoNzDPY1QqHgjso3D',
                            //     rectangle: window.destBoundingBox
                            // });
                            // window.viewer.imageryLayers.addImageryProvider(wms);
                        }, 1500);
                        if (viewer.scene.camera.position) {
                            viewer.camera.flyTo({
                                destination: viewer.scene.camera.position,
                                orientation: {
                                    heading: Cesium.Math.toRadians(0.0),
                                    pitch: Cesium.Math.toRadians(-90.0),
                                    roll: 0.0
                                }
                            });
                        }
                    }
                }
            }, 2000);
            /*
            const eventLoaded = (tiles) => {
                if (tiles === 0) {
                    setLoading(false);
                    console.log('loaded');
                }
            };
            update({ type: CESIUM_VIEWER, payload: viewer });
            const loader = viewer.scene.globe.tileLoadProgressEvent;
            loader.addEventListener(eventLoaded);
            */
        }
        // eslint-disable-next-line
        if (!document.querySelector('twinworld-app').shadowRoot.querySelector('.cesium-viewer-geocoderContainer form span.searchBtn')) {
            const btnSpan = document.createElement('span');
            btnSpan.classList.add('searchBtn');
            btnSpan.innerHTML = `<button class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-1vas6gj-MuiButtonBase-root-MuiIconButton-root" tabindex="0" type="button" aria-label="search" style="
                             transform: translateX(300px) translateY(-40px);">
                             <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="SearchIcon">
                             <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                             </svg><span class="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span></button>`;
            document.querySelector('twinworld-app').shadowRoot.querySelector('.cesium-viewer-geocoderContainer form').append(btnSpan);
        }
    }, []);

    const handleChange = (event) => {
        const show3d = event.target.checked;
        if (show3d) {
            if (viewer.scene.camera.position) {
                viewer.camera.flyTo({
                    destination: viewer.scene.camera.position,
                    orientation: {
                        heading: Cesium.Math.toRadians(20.0),
                        pitch: Cesium.Math.toRadians(-35.0),
                        roll: 0.0
                    }
                });
            }
            //document.querySelector('twinworld-app').shadowRoot.querySelectorAll('.cesium-baseLayerPicker-item')[0].click();
            // try {
            //window.googlePhotoRealisticTileset = window.viewer.scene.primitives.add(window.googlePhotoRealisticTiles);
            // window.buildingsTileset = window.viewer.scene.primitives.add(Cesium.createOsmBuildings());
            // } catch (err) {
            // console.error('3d view');
            // }
        } else {
            if (viewer.scene.camera.position) {
                viewer.camera.flyTo({
                    destination: viewer.scene.camera.position,
                    orientation: {
                        heading: Cesium.Math.toRadians(0.0),
                        pitch: Cesium.Math.toRadians(-90.0),
                        roll: 0.0
                    }
                });
            }
            // if (window.googlePhotoRealisticTileset !== undefined && window.buildingsTileset !== undefined) {
            //     window.viewer.scene.primitives.remove(window.buildingsTileset);
            //     window.viewer.scene.primitives.remove(window.googlePhotoRealisticTileset);
            // }
            // document.querySelector('twinworld-app').shadowRoot.querySelectorAll('.cesium-baseLayerPicker-item')[2].click();
        }
        setShow3dMap(show3d);
        console.log('Change event ', event);
    };

    return (
        <>
            {/* {isLoading ? <img alt="loader" src={https://twinworld-component.s3.amazonaws.com/build/utils/lib/loader.gif'} style={{ height: 'calc(100vh)', width: 'calc(100vw)' }} /> : null}
            <div style={isLoading ? { visibility: 'hidden' } : null}> */}
            <ViewerResium
                // imageryProvider={false}
                baseLayerPicker={true}
                requestRenderMode={true}
                terrainProvider={terrainProvider}
                ref={ref}
                style={{ height: 'calc(96vh)' }}
            />
            <FormGroup>
                <FormControlLabel
                    id="twoDimThreeDimSwitch"
                    control={<Basic2d3dSwitch checked={show3dMap} onChange={handleChange} />}
                    label="Android 12"
                />
            </FormGroup>
            {/* </div> */}
        </>
    );
}

export default App;
