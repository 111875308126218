// types
import * as React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// initial state
const initialState = {
    openItem: [''],
    openComponent: 'buttons',
    drawerOpen: true,
    componentDrawerOpen: true,
    alertOpen: false
};

// ==============================|| SLICE - MENU ||============================== //

window.captureScreen = () => {
    var sceneResolution = 1.0;

    var scene = viewer.scene;

    if (!scene) {
        console.error('No scene');
    }

    var prepareScreenCapture = function () {
        viewer.resolutionScale = sceneResolution;

        scene.preRender.removeEventListener(prepareScreenCapture);

        setTimeout(function () {
            scene.postRender.addEventListener(snapCamera);
        }, 100);
    };

    function renderImage(uri, name) {
        var img = document.createElement('img');
        img.style.height = '40px';
        img.src = uri;
        document.querySelector('twinworld-app').shadowRoot.querySelector('#snapshots').append(img);
    }

    function createTwin(file) {
        if (window.taskToUse) {
            const dataValue = JSON.parse(window.taskToUse);
            if (dataValue.building_id && !dataValue.buildingid) {
                dataValue.buildingid = dataValue.building_id;
            }
            window
                .saveScene(
                    {
                        buildingid: dataValue.buildingid,
                        siteid: dataValue.siteid,
                        scene_id: dataValue.scene_id,
                        assetName: window.currentAssetName,
                        boundingBox: window.destBoundingBox,
                        latitude: document.querySelector('twinworld-app').shadowRoot.getElementById(`${window.currentAssetName}_lat`)
                            ? (document.querySelector('twinworld-app').shadowRoot.getElementById(`${window.currentAssetName}_lat`).value *
                                  180) /
                              Cesium.Math.PI
                            : (window.destCenter.latitude * 180) / Cesium.Math.PI,
                        height: window.destCenter.height,
                        longitude: document.querySelector('twinworld-app').shadowRoot.getElementById(`${window.currentAssetName}_lon`)
                            ? (document.querySelector('twinworld-app').shadowRoot.getElementById(`${window.currentAssetName}_lon`).value *
                                  180) /
                              Cesium.Math.PI
                            : (window.destCenter.longitude * 180) / Cesium.Math.PI,
                        pois: JSON.stringify(window.allPickedAssets),
                        rotation: JSON.stringify(window.drone.orientation._value),
                        scale: '' + window.drone.model.scale._value,
                        airQuality: JSON.stringify(window.airQuality),
                        cameraPosition: JSON.stringify({
                            destination: viewer.scene.camera.position,
                            orientation: {
                                heading: viewer.camera.heading,
                                pitch: viewer.camera.pitch,
                                roll: viewer.camera.roll
                            }
                        })
                    },
                    true
                )
                .then((sceneId) => {
                    console.log('Scene Id ', sceneId);
                    document.querySelector('twinworld-app').shadowRoot.querySelector('.loaderbg').style.display = 'block';
                    const formData = new FormData();
                    formData.append('permission', 'draft');
                    formData.append('upload_files', file);
                    formData.append('name', window.currentAssetName.split('/').splice(-1, 1)[0]);
                    formData.append('descriptions', '');
                    formData.append('description', '');
                    formData.append('world_id', sceneId);
                    formData.append('tags', []);
                    formData.append('folders', []);
                    axios
                        .post(`https://arche.thetwinmaster.com/api/v1/twin/`, formData, {
                            withCredentials: true
                        })
                        .then((res) => {
                            document.querySelector('twinworld-app').shadowRoot.querySelector('.loaderbg').style.display = 'none';
                            const resData = res.data;
                            console.log(resData);
                        })
                        .catch((err) => {
                            document.querySelector('twinworld-app').shadowRoot.querySelector('.loaderbg').style.display = 'none';
                            console.error(err);
                        });
                });
        }
    }

    var snapCamera = function () {
        scene.postRender.removeEventListener(snapCamera);

        var canvas = scene.canvas;

        canvas.toBlob(function (blob) {
            var url = URL.createObjectURL(blob);
            const file = new File([blob], 'image');
            //renderImage(url, 'snapshot-' + sceneResolution.toString() + 'x.png');
            createTwin(file);
            viewer.resolutionScale = 1.0;
            // window.showAlert('Twin created');
        });
    };

    scene.preRender.addEventListener(prepareScreenCapture);

    viewer.camera.flyTo({
        destination: viewer.scene.camera.position,
        orientation: {
            heading: viewer.camera.heading - 0.001,
            pitch: viewer.camera.pitch,
            roll: viewer.camera.roll
        }
    });
};

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        activeItem(state, action) {
            document
                .querySelector('twinworld-app')
                .shadowRoot.querySelectorAll('.left-toolbar-container')
                .forEach((leftTool) => {
                    leftTool.style.width = 0;
                    leftTool.style.display = 'none';
                    leftTool.style.visibility = 'hidden';
                });
            document
                .querySelector('twinworld-app')
                .shadowRoot.querySelectorAll('.topup-toolbar-container')
                .forEach((topupTool) => {
                    topupTool.style.height = 0;
                    topupTool.style.visibility = 'hidden';
                });

            if (state.openItem[0] !== 'modelOnMap') {
                window.siteSelectionActive = false;
            }

            state.openItem = action.payload.openItem;
            console.log('Open item ::', state.openItem);
            if (window.activeOption === state.openItem[0]) {
                window.activeOption = '';
            } else {
                window.activeOption = state.openItem[0];
            }
            if (state.openItem[0] === 'collaborate') {
                window.location.assign('https://twinup.dev.cityzenith.com/');
            } else if (state.openItem[0] === 'world') {
                window.location.assign('/');
            } else if (state.openItem[0] === 'building') {
                window.location.assign('/');
            } else if (state.openItem[0] === 'screenshot') {
                captureScreen();
            } else if (state.openItem[0] === 'models') {
                document.querySelector('twinworld-app').shadowRoot.querySelector('#navChanger').click();
            } else if (state.openItem[0] === 'search') {
                if (window.applyGlobeSpinEvent) {
                    window.applyGlobeSpinEvent();
                }
                viewer.clock.onTick.removeEventListener(window.applyGlobeSpin);
                if (
                    document.querySelector('twinworld-app').shadowRoot.querySelector('.cesium-viewer-geocoderContainer').style.display ===
                    'block'
                ) {
                    document.querySelector('twinworld-app').shadowRoot.querySelector('.cesium-viewer-geocoderContainer').style.display =
                        'block';
                } else {
                    document
                        .querySelector('twinworld-app')
                        .shadowRoot.querySelector('.cesium-viewer-geocoderContainer .cesium-geocoder-input').style.width = '250px';
                    document.querySelector('twinworld-app').shadowRoot.querySelector('.cesium-viewer-geocoderContainer').style.display =
                        'block';
                }
                /*
                setTimeout(() => {
                    document
                        .querySelector('twinworld-app')
                        .shadowRoot.querySelector('.cesium-viewer-geocoderContainer .cesium-geocoder-input').style.width = '0';
                    document.querySelector('twinworld-app').shadowRoot.querySelector('.cesium-viewer-geocoderContainer').style.display =
                        'none';
                }, 12000);
                */
            } else if (state.openItem[0] === 'maps') {
                document.querySelector('twinworld-app').shadowRoot.querySelector('#legendChanger').click();
            } else if (state.openItem[0] === 'marque') {
                document.querySelector('twinworld-app').shadowRoot.querySelector('#marqueChanger').click();
            } else if (state.openItem[0] === 'tapeMeasure') {
                window.changeOriginOnClickGlobal = true;
                document.querySelector('twinworld-app').shadowRoot.querySelector('#Measurement').click();
            } else if (state.openItem[0] === 'sun') {
                viewer.scene.light.intensity = (viewer.scene.light.intensity + 20) % 100;
            } else if (state.openItem[0] === 'robot') {
                window.changeOriginOnClickGlobal = false;
                window.startRobotScanner(200, true);
            } else if (state.openItem[0] === 'contextData') {
                /*if (!window.scanState || window.scanState === 'complete') {
                    window.changeOriginOnClickGlobal = false;
                    window.startRobotScanner(200, true);
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.display = 'block';
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.visibility = 'visible';
                }*/

                if (document.querySelector('twinworld-app').shadowRoot.querySelector('#search-submenu').style.display === 'inline-table') {
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#search-submenu').style.display = 'none';
                } else {
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#search-submenu').style.display = 'inline-table';
                }

                if (
                    document.querySelector('twinworld-app').shadowRoot.querySelector('.cesium-viewer-geocoderContainer').style.display ===
                    'block'
                ) {
                    document.querySelector('twinworld-app').shadowRoot.querySelector('.cesium-viewer-geocoderContainer').style.display =
                        'block';
                }
            } else if (state.openItem[0] === 'measurePerf') {
                // window.setupBuildingPerformance();
                document.querySelector('twinworld-app').shadowRoot.querySelector('#buildingMetricsVisualizer').click();
            } else if (state.openItem[0] === 'modelOnMap') {
                if (
                    document.querySelector('twinworld-app').shadowRoot.querySelector('.cesium-viewer-geocoderContainer').style.display ===
                    ''
                ) {
                    if (window.applyGlobeSpinEvent) {
                        window.applyGlobeSpinEvent();
                    }
                    viewer.clock.onTick.removeEventListener(window.applyGlobeSpin);
                }
                if (
                    document.querySelector('twinworld-app').shadowRoot.querySelector('.cesium-viewer-geocoderContainer').style.display ===
                        '' ||
                    document.querySelector('twinworld-app').shadowRoot.querySelector('.cesium-viewer-geocoderContainer').style.display ===
                        'none'
                ) {
                    document.querySelector('twinworld-app').shadowRoot.querySelector('.cesium-viewer-geocoderContainer').style.display =
                        'block';
                } else {
                    document.querySelector('twinworld-app').shadowRoot.querySelector('.cesium-viewer-geocoderContainer').style.display =
                        'block';
                }

                window.siteSelectionActive = true;
                window.setupAreaOfInterest();
                /*
                if (
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#legend-container').style.display === '' ||
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#legend-container').style.display === 'none'
                ) {
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#legend-container').style.display = 'block';
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#legend-container').style.visibility = 'visible';
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#legend-container').style.width = '304px';
                } else {
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#legend-container').style.visibility = 'hidden';
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#legend-container').style.display = 'none';
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#legend-container').style.width = '0px';
                }*/
            } else if (window.activeOption === 'move') {
                if (
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#transform-submenu').style.display === '' ||
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#transform-submenu').style.display === 'none'
                ) {
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#transform-submenu').style.display = 'block';
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#transform-submenu').style.visibility = 'visible';
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#transform-submenu').style.width = '304px';
                } else {
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#transform-submenu').style.visibility = 'hidden';
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#transform-submenu').style.display = 'none';
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#transform-submenu').style.width = '0px';
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#move-container').style.display = 'none';
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#rotate-container').style.display = 'none';
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#scale-container').style.display = 'none';
                }
            } else if (state.openItem[0] === 'scale') {
                if (
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#scale-container').style.display === '' ||
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#scale-container').style.display === 'none'
                ) {
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#scale-container').style.display = 'block';
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#scale-container').style.visibility = 'visible';
                } else {
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#scale-container').style.visibility = 'hidden';
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#scale-container').style.display = 'none';
                }
            } else if (state.openItem[0] === 'voice') {
                document.querySelector('twinworld-app').shadowRoot.querySelector('#sayArchie').click();
            }
            if (window.activeOption === '') {
                document.querySelector('twinworld-app').shadowRoot.querySelector('.cesium-viewer-geocoderContainer').style.display =
                    'block';
            }
        },

        activeComponent(state, action) {
            state.openComponent = action.payload.openComponent;
        },

        openDrawer(state, action) {
            state.drawerOpen = action.payload.drawerOpen;
        },

        openComponentDrawer(state, action) {
            state.componentDrawerOpen = action.payload.componentDrawerOpen;
        }
    }
});

export default menu.reducer;

export const { activeItem, activeComponent, openDrawer, openComponentDrawer } = menu.actions;
