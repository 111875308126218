import * as React from 'react';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import Cookies from 'js-cookie';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
// import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = SwipeableViews;

var BASE_GRAPHQL_URL = 'https://arche.thetwinmaster.com/sim';

const getBuildingUriGlb = (buildingId, buildingUriGlb, buildingUriThumbnail) => {
    // console.log(`callAPI with ${uri_glb}...`);
    const parseS3Url = require('parse-aws-s3-url');
    const parts = parseS3Url(buildingUriGlb);
    const thumbnailParts =
        buildingUriThumbnail !== ''
            ? parseS3Url(buildingUriThumbnail)
            : {
                  Bucket: '',
                  Key: ''
              };

    //=> parts = {Bucket: 'my-bucket', Key: 'path/to/my/file.png'}
    const bucket = parts.Bucket;
    const key = parts.Key;
    const thumbnailBucket = thumbnailParts.Bucket;
    const thumbnailKey = thumbnailParts.Key;

    return fetch(
        `https://arche.thetwinmaster.com/twinworld/getGlbModel?bucket=${bucket}&s3_key=${key}&thumbnail_bucket=${thumbnailBucket}&s3_thumbnail_key=${thumbnailKey}`
    )
        .then((res) => res.json())
        .then(
            (result) => {
                // console.log(`Success: calling download_glb API with result ${JSON.stringify(result, null, 4)}`);
                // console.log(`Result: ${JSON.stringify(result, null, 4)}`);
                const glb_path = result.glb_path.replace('./utils', 'https://arche.thetwinmaster.com/community/twinworld/utils');
                const thumbnail_path =
                    result.thumbnail_path && result.thumbnail_path !== ''
                        ? result.thumbnail_path.replace('./utils', 'https://arche.thetwinmaster.com/community/twinworld/utils')
                        : '';
                // console.log(`GLB Path: ${JSON.stringify(glb_path)}`);
                return {
                    glb: glb_path,
                    thumbnail: thumbnail_path
                };
            },
            (error) => {
                console.log(`Error: calling download_glb API with message ${error}`);
            }
        );
};

function drag(ev) {
    const modelBeingDragged = ev.target.children[0]['data-value'];
    console.log('model being dragged :', modelBeingDragged);
    ev.dataTransfer.setData('text', JSON.parse(modelBeingDragged)['uri_glb']);
}

const taskItems = (taskList, modelUploadingInProcess) => {
    const taskItems = taskList.map((task, index) => (
        <div key={task.building_id} style={{ display: 'grid', placeItems: 'center', width: '80px' }} className="item" data-value="1">
            {task.local_glb_uri && (
                <model-viewer
                    ondragstart={() => {
                        drag(event);
                    }}
                    style={{
                        border: '2px solid rgb(243 111 75 / 56%)',
                        background: '#5c5a5a 50% / cover no-repeat',
                        borderRadius: '10px',
                        width: '75px',
                        height: '50px',
                        cursor: 'pointer',
                        marginLeft: '5px',
                        marginRight: '5px'
                    }}
                    // src="https://twinworld-component.s3.amazonaws.com/build/utils/lib/73a1799a-c905-40d3-1688-2e9c1fdc2912_BldgandHVAC.glb"
                    src={task.local_glb_uri}
                    poster={`${
                        task.local_thumbnail_uri ? task.local_thumbnail_uri : 'https://dtuas2sql0dg4.cloudfront.net/build/hoverToLoad.png'
                    }`}
                    reveal="manual"
                    data-value={JSON.stringify(task)}
                    // ar
                    // shadow-intensity="1"
                    // camera-controls
                    // touch-action="pan-y"
                    onMouseOver={(ev) => {
                        ev.target.poster = '';
                        ev.target.dismissPoster();
                    }}
                    onMouseLeave={(ev) => {
                        ev.target.poster = 'https://dtuas2sql0dg4.cloudfront.net/build/hoverToLoad.png';
                    }}
                    onClick={(ev) => {
                        const dataValue = JSON.parse(ev.target.getAttribute('data-value'));
                        window.taskToUse = ev.target.getAttribute('data-value');
                        if (window.applyGlobeSpinEvent) {
                            window.applyGlobeSpinEvent();
                        }
                        viewer.clock.onTick.removeEventListener(window.applyGlobeSpin);
                        try {
                            window.dropNamedAsset(dataValue.local_glb_uri);
                        } catch (err) {
                            console.error('Failed to drop asset');
                        }
                    }}
                />
            )}
            {!task.local_glb_uri && (
                <div
                    style={{
                        width: '75px',
                        height: '50px',
                        border: '2px solid rgb(243 111 75 / 56%)',
                        background: '#5c5a5a 50% / cover no-repeat',
                        borderRadius: '10px',
                        display: 'grid',
                        placeContent: 'center',
                        marginLeft: '5px',
                        marginRight: '5px'
                    }}
                >
                    <CircularProgress />
                </div>
            )}
        </div>
    ));
    if (modelUploadingInProcess) {
        taskItems.push(
            <div
                key={Math.ceil(Math.random() * 100000)}
                style={{ display: 'grid', placeItems: 'center', width: '80px' }}
                className="item"
                data-value="1"
            >
                <div
                    style={{
                        width: '75px',
                        height: '50px',
                        border: '2px solid rgb(243 111 75 / 56%)',
                        background: '#5c5a5a 50% / cover no-repeat',
                        borderRadius: '10px',
                        display: 'grid',
                        placeContent: 'center',
                        marginLeft: '5px',
                        marginRight: '5px'
                    }}
                >
                    <CircularProgress />
                </div>
            </div>
        );
    }
    return taskItems;
};

const responsive = {
    0: { items: 1 },
    268: { items: 3 },
    1024: { items: 3 }
};

function Tasks(props) {
    const theme = useTheme();
    const { modelUploaded, modelUploadingInProcess } = props;
    const [buildings, setBuildings] = React.useState([]);
    const [activeStep, setActiveStep] = React.useState(0);
    const [taskList, setTaskList] = React.useState([]);

    const buildingDetails = (accessToken, buildingGuid) => {
        const url = `${BASE_GRAPHQL_URL}`;
        const variables = {};
        const query = `
        query {
            Building(id: "${buildingGuid}") {
                id
                name
                s3location
            }
        }`;

        return axios
            .post(
                url,
                {
                    query,
                    variables
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )
            .then((response) => response.data);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    React.useEffect(() => {
        const twinupCookie = Cookies.get('arche-twinup');
        const cookieData = JSON.parse(twinupCookie.replace(/\\054/g, ',').replace(/\\/g, '').replace(/^\"/, '').replace(/\"$/, ''));
        console.log('Task list before rendering ', taskList);
        setTimeout(() => {
            fetch('https://arche.thetwinmaster.com/api/v1/task/list', {
                headers: { Authorization: `Bearer ${cookieData.access_token}` },
                credentials: 'include'
            })
                .then((res) => res.json())
                .then((_taskList) => {
                    // console.log('New Task list ', _taskList);
                    const taskMap = {};
                    _taskList.forEach((task) => {
                        const buildingId = task['building_id'];
                        const twinId = task['twin_id'];
                        if (buildingId !== '') {
                            taskMap[buildingId] = task;
                            buildingDetails(cookieData.access_token, buildingId).then((buildingData) => {
                                const buildingS3Location = buildingData.data.Building[0].s3location;
                                taskMap[buildingId]['uri_glb'] = buildingS3Location;
                                const substring = ',';
                                const index = buildingS3Location.indexOf(substring);
                                if (buildingS3Location !== '') {
                                    const formattedBuildingS3Loc =
                                        index !== -1 ? buildingS3Location.substring(0, index) : buildingS3Location;
                                    const formattedThumbnailLoc =
                                        index !== -1 ? buildingS3Location.substring(index + 1, buildingS3Location.length) : '';
                                    const leanModelS3Location =
                                        buildingData.data.Building[0].StructuralSystem &&
                                        buildingData.data.Building[0].StructuralSystem.s3location
                                            ? buildingData.data.Building[0].StructuralSystem.s3location
                                            : null;
                                    const finalBuildingS3Loc = leanModelS3Location ? leanModelS3Location : formattedBuildingS3Loc;
                                    getBuildingUriGlb(buildingId, finalBuildingS3Loc, formattedThumbnailLoc).then((buildingGlbUri) => {
                                        taskMap[buildingId]['local_glb_uri'] = buildingGlbUri.glb;
                                        taskMap[buildingId]['local_thumbnail_uri'] = buildingGlbUri.thumbnail;
                                        const currTaskList = Object.values(taskMap);
                                        setTaskList(currTaskList);
                                        window.twinWorldTaskList = currTaskList;
                                    });
                                } else {
                                    const currTaskList = Object.values(taskMap);
                                    setTaskList(currTaskList);
                                    window.twinWorldTaskList = currTaskList;
                                }
                                // console.log('New Task building added to task list ', task, Object.values(taskMap));
                            });
                        }
                    });
                })
                .catch((err) => {
                    console.log('Failed to fetch tasks ', err);
                });
        }, 1000);

        // eslint-disable-next-line
    }, [modelUploaded]);

    return (
        <Box sx={{ maxWidth: 1200, flexGrow: 1 }}>
            <AliceCarousel
                mouseTracking
                // items={items}
                items={taskItems(taskList, modelUploadingInProcess)}
                responsive={responsive}
                controlsStrategy="alternate"
                disableDotsControls="true"
                renderPrevButton={() => {
                    return (
                        <p
                            style={{ position: 'absolute', left: '-3px', top: '23px', transform: 'scale(0.8) translateY(-10px)' }}
                            className="p-4 absolute left-0 top-0"
                        >
                            <img alt="left" src="https://twinworld-component.s3.amazonaws.com/build/utils/lib/LeftArrow.png" />
                        </p>
                    );
                }}
                renderNextButton={() => {
                    return (
                        <p style={{ position: 'absolute', right: '10px', top: '0' }} className="p-4 absolute right-0 top-0">
                            {/* <img alt="right" src="https://twinworld-component.s3.amazonaws.com/build/utils/lib/RightArrow.png" /> */}
                        </p>
                    );
                }}
            />
        </Box>
    );
}

export default Tasks;
