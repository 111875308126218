import * as React from 'react';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Zoom from '@mui/material/Zoom';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import AliceCarousel from 'react-alice-carousel';
import Cookies from 'js-cookie';
import 'react-alice-carousel/lib/alice-carousel.css';
// import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = SwipeableViews;

const SceneTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9'
    }
}));

function createGrid(rectangleHalfSize) {
    const gridWidth = 41;
    const gridHeight = 41;
    const everestLatitude = Cesium.Math.toRadians(51.51786);
    const everestLongitude = Cesium.Math.toRadians(-0.102216);
    const e = new Cesium.Rectangle(
        everestLongitude - rectangleHalfSize,
        everestLatitude - rectangleHalfSize,
        everestLongitude + rectangleHalfSize,
        everestLatitude + rectangleHalfSize
    );
    const terrainSamplePositions = [];
    for (let y = 0; y < gridHeight; ++y) {
        for (let x = 0; x < gridWidth; ++x) {
            const longitude = Cesium.Math.lerp(e.west, e.east, x / (gridWidth - 1));
            const latitude = Cesium.Math.lerp(e.south, e.north, y / (gridHeight - 1));
            const position = new Cesium.Cartographic(longitude, latitude);
            terrainSamplePositions.push(position);
        }
    }
    return terrainSamplePositions;
}

const sceneItems = (sceneList) => {
    const allScenes = sceneList.map((scene, index) => (
        <div
            key={scene.scene_id}
            style={{ display: 'grid', placeItems: 'center', width: '75px', marginLeft: '5px', marginRight: '5px', cursor: 'pointer' }}
            className="item"
            data-value="1"
        >
            {!scene.local_glb_uri && (
                <div
                    style={{
                        border: '2px solid rgb(243 111 75 / 56%)',
                        background: '#5c5a5a 50% / cover no-repeat',
                        borderRadius: '10px',
                        width: '75px',
                        height: '50px',
                        display: 'grid',
                        placeContent: 'center'
                    }}
                >
                    <CircularProgress />
                </div>
            )}
            {scene.local_glb_uri && (
                <SceneTooltip
                    TransitionComponent={Zoom}
                    placement="bottom"
                    sx={{
                        width: '200px'
                    }}
                    title={
                        <React.Fragment>
                            <Typography color="inherit" style={{ fontSize: '12px' }}>
                                <b>Name</b> :{scene.name ? scene.name : ' '}
                            </Typography>
                            <Typography color="inherit" style={{ fontSize: '12px' }}>
                                <b>Coordinates</b>: ({scene.latitude}, {scene.longitude})
                            </Typography>
                            <Typography color="inherit" style={{ fontSize: '12px' }}>
                                <b>Location</b>: {scene.placeName}
                            </Typography>
                        </React.Fragment>
                    }
                    arrow
                >
                    {!scene.name ? (
                        <model-viewer
                            style={{
                                border: '2px solid rgb(243 111 75 / 56%)',
                                background: '#5c5a5a 50% / cover no-repeat',
                                borderRadius: '10px',
                                width: '75px',
                                height: '50px'
                            }}
                            ondragstart={() => {
                                drag(event);
                            }}
                            src={`${scene.local_glb_uri}`}
                            poster={`${'https://dtuas2sql0dg4.cloudfront.net/build/hoverToLoad.png'}`}
                            reveal="manual"
                            data-value={JSON.stringify(scene)}
                            onMouseOver={(ev) => {
                                ev.target.poster = '';
                                ev.target.dismissPoster();
                            }}
                            onMouseLeave={(ev) => {
                                ev.target.poster = 'https://dtuas2sql0dg4.cloudfront.net/build/hoverToLoad.png';
                            }}
                            onClick={(ev) => {
                                if (window.applyGlobeSpinEvent) {
                                    window.applyGlobeSpinEvent();
                                }
                                viewer.clock.onTick.removeEventListener(window.applyGlobeSpin);
                                const dataValue = JSON.parse(ev.target.getAttribute('data-value'));
                                window.sceneCreateOrUpdate = 'update';
                                loadScene(dataValue);
                                window.sceneToUpdate = ev.target.getAttribute('data-value');
                                window.addScene();
                            }}
                        />
                    ) : (
                        <div
                            role="button"
                            tabIndex={0}
                            style={{
                                border: '2px solid rgb(243 111 75 / 56%)',
                                background: '#5c5a5a 50% / cover no-repeat',
                                borderRadius: '10px',
                                width: '75px',
                                height: '50px',
                                backgroundImage: `url('${scene.local_thumbnail_uri}')`
                            }}
                            ondragstart={() => {
                                drag(event);
                            }}
                            data-value={JSON.stringify(scene)}
                            onClick={(ev) => {
                                if (window.applyGlobeSpinEvent) {
                                    window.applyGlobeSpinEvent();
                                }
                                viewer.clock.onTick.removeEventListener(window.applyGlobeSpin);
                                const dataValue = JSON.parse(ev.target.getAttribute('data-value'));
                                window.sceneCreateOrUpdate = 'update';
                                loadScene(dataValue);
                                window.sceneToUpdate = ev.target.getAttribute('data-value');
                                window.addScene();
                            }}
                            onKeyDown={(ev) => {
                                if (window.applyGlobeSpinEvent) {
                                    window.applyGlobeSpinEvent();
                                }
                                viewer.clock.onTick.removeEventListener(window.applyGlobeSpin);
                                const dataValue = JSON.parse(ev.target.getAttribute('data-value'));
                                window.sceneCreateOrUpdate = 'update';
                                loadScene(dataValue);
                                window.sceneToUpdate = ev.target.getAttribute('data-value');
                                window.addScene();
                            }}
                        />
                    )}
                </SceneTooltip>
            )}
        </div>
    ));
    // allScenes.push(
    //     <button
    //         style={{ display: 'grid', placeItems: 'center', width: '60px', border: 'none', backgroundColor: '#363c50', cursor: 'pointer' }}
    //         className="item"
    //         data-value="5"
    //         onClick={() => {
    //             window.sceneCreateOrUpdate = 'create';
    //             window.addScene();
    //         }}
    //     >
    //         <img
    //             style={{ border: '2px solid grey', borderRadius: '10px', width: '75px', height: '50px' }}
    //             alt="Serbia"
    //             src="https://twinworld-component.s3.amazonaws.com/build/utils/lib/NewScene.png"
    //         />
    //     </button>
    // );
    return allScenes;
};

window.saveScene = (sceneData, isNewScene) => {
    document.querySelector('twinworld-app').shadowRoot.querySelector('.loaderbg').style.display = 'block';
    if (isNewScene) {
        return new Promise((resolve, reject) => {
            fetch('https://arche.thetwinmaster.com/twinworld/createScene', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(sceneData)
            })
                .then((res) => res.text())
                .then((sceneId) => {
                    document.querySelector('twinworld-app').shadowRoot.querySelector('.loaderbg').style.display = 'none';
                    resolve(sceneId);
                });
        });
    } else {
        return new Promise((resolve, reject) => {
            fetch('https://arche.thetwinmaster.com/twinworld/updateScene', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(sceneData)
            })
                .then((res) => res.text())
                .then((sceneId) => {
                    document.querySelector('twinworld-app').shadowRoot.querySelector('.loaderbg').style.display = 'none';
                    resolve(sceneId);
                });
        });
    }
};

const responsive = {
    0: { items: 1 },
    268: { items: 3 },
    1024: { items: 3 }
};

var BASE_GRAPHQL_URL = 'https://arche.thetwinmaster.com/sim';

const loadBuildingScene = (scene) => {
    document.querySelector('twinworld-app').shadowRoot.querySelector('main').innerHTML = `<model-viewer
        style="
            border: 2px solid rgb(243 111 75 / 56%);
            background: #363C4F 100% / cover no-repeat;
            width: calc(100vw - 64px);
            height: calc(100vh - 104px);
            margin-top: -24px;
        "
        src="${scene.local_glb_uri}"
        ar
        shadow-intensity="1" camera-controls touch-action="pan-y"
    />`;
};

const loadScene = (scene) => {
    const dataValue = scene;
    window.destBoundingBoxStr = JSON.parse(dataValue.boundingBox);
    window.destBoundingBox = new Cesium.Rectangle(
        window.destBoundingBoxStr.west,
        window.destBoundingBoxStr.south,
        window.destBoundingBoxStr.east,
        window.destBoundingBoxStr.north
    );
    const latLonCoords = {
        latitude: (parseFloat(dataValue.latitude) * Cesium.Math.PI) / 180,
        longitude: (parseFloat(dataValue.longitude) * Cesium.Math.PI) / 180
    };
    const height = parseFloat(dataValue.height);
    window.destinationSeaLevelHeight = height;

    const rotation = dataValue.rotation ? JSON.parse(dataValue.rotation) : null;

    const scale = dataValue.scale ? parseFloat(dataValue.scale) : null;

    const resJson = dataValue.pois ? JSON.parse(dataValue.pois) : [];
    window.allPickedAssets = resJson;
    window.entityList = Array.from(
        new Set(
            resJson
                .filter((pickedAsset) => {
                    return pickedAsset.name && !pickedAsset.name.endsWith('undefined )');
                })
                .filter((pickedAsset) => pickedAsset.name !== window.currentAssetName)
                .map((pickedAsset) => (
                    <span>
                        {pickedAsset.name.split('( Status')[0]}
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {'    ( Status' + pickedAsset.name.split('( Status')[1]}
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lat: {pickedAsset.latitude.toFixed(3)}&nbsp; Lon:{' '}
                        {pickedAsset.longitude.toFixed(3)}
                    </span>
                ))
        )
    );
    const cameraPosition = JSON.parse(dataValue.cameraPosition);
    viewer.camera.flyTo({
        destination: cameraPosition.destination,
        orientation: {
            heading: cameraPosition.orientation.heading,
            pitch: cameraPosition.orientation.pitch,
            roll: cameraPosition.orientation.roll
        }
    });
    window.scanState = 'complete';
    if (document.querySelector('twinworld-app').shadowRoot.getElementById('contextData')) {
        document.querySelector('twinworld-app').shadowRoot.getElementById('contextData').click();
    }

    setTimeout(() => {
        if (document.querySelector('twinworld-app').shadowRoot.getElementById('contextData')) {
            document.querySelector('twinworld-app').shadowRoot.getElementById('contextData').click();
        }
        document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.display = 'block';
        document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.visibility = 'visible';
    }, 20);
    const showSceneDetails = () => {
        window.poiFlagPoints = [];
        window.destinationSeaLevelHeight = resJson
            .filter((data) => {
                return !data.name.endsWith('undefined )');
            })
            .forEach((formattedPoi, index) => {
                var flag = new Cesium.PinBuilder();
                var flagProperties = new Cesium.PropertyBag();
                flagProperties.addProperty('MyProperty_A', 0);
                flagProperties.addProperty('MyProperty_B', 1);
                // Create an Entity
                var flagPoint = viewer.entities.add({
                    name: 'pole',
                    position: Cesium.Cartesian3.fromDegrees(
                        formattedPoi.longitude,
                        formattedPoi.latitude,
                        window.destinationSeaLevelHeight
                    ),
                    billboard: {
                        image: flag.fromUrl(formattedPoi.pinIcon, new Cesium.Color.fromCssColorString('#1e90ff99'), 50),
                        // image: flag.fromUrl(formattedPoi.pinIcon, Cesium.Color.DODGERBLUE, 50),
                        verticalOrigin: Cesium.VerticalOrigin.BOTTOM
                    },
                    properties: flagProperties
                });
                window.poiFlagPoints.push(flagPoint);
                var flagNumPoint = viewer.entities.add({
                    name: 'pole',
                    position: Cesium.Cartesian3.fromDegrees(
                        formattedPoi.longitude,
                        formattedPoi.latitude,
                        window.destinationSeaLevelHeight
                    ),
                    billboard: {
                        image: flag.fromText(index + 1, Cesium.Color.DODGERBLUE, 25).toDataURL(),
                        verticalOrigin: Cesium.VerticalOrigin.BOTTOM
                    },
                    properties: flagProperties
                });
                window.poiFlagPoints.push(flagNumPoint);
            });
        viewer.camera.flyTo({
            destination: cameraPosition.destination,
            orientation: {
                heading: cameraPosition.orientation.heading - 0.0001,
                pitch: cameraPosition.orientation.pitch,
                roll: cameraPosition.orientation.roll
            }
        });
    };
    if (!window.twinWorldSceneList) {
        setTimeout(() => {
            showSceneDetails();
        }, 5000);
    } else {
        setTimeout(() => {
            showSceneDetails();
        }, 3000);
    }

    try {
        // window.dropNamedAsset(dataValue.local_glb_uri, latLonCoords, true);
        window.destSelectedCenter = {
            latitude: parseFloat(dataValue.latitude),
            longitude: parseFloat(dataValue.longitude),
            height: height
        };
        window.activeOption = 'contextData';
        window.createModel(
            dataValue.local_glb_uri,
            Cesium.Cartographic.toCartesian(
                new Cesium.Cartographic.fromDegrees(
                    parseFloat(dataValue.longitude),
                    parseFloat(dataValue.latitude),
                    parseFloat(dataValue.height)
                )
            ),
            scale,
            rotation
        );
        // if (scale) {
        //     // alert(`Building being scaled to : ${scale}`);
        //     // window.drone.model.scale = scale;
        // }
        // if (rotation) {
        //     window.drone.orientation = rotation;
        // }
    } catch (err) {
        console.error('Failed to drop asset');
    }
};

function Scenes() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const [taskList, setTaskList] = React.useState([]);
    const [sceneList, setSceneList] = React.useState([]);
    const [sceneItemsDom, setSceneItemsDom] = React.useState([]);
    const [twinupCookie] = React.useState(Cookies.get('arche-twinup'));

    const buildingDetails = (accessToken, buildingGuid, buildingS3Location, structuralSystemS3Location) => {
        console.log(`building ID: ${buildingGuid}`);

        const url = `${BASE_GRAPHQL_URL}`;
        const variables = {};
        const query = `
        query {
            Building(id: "${buildingGuid}") {
                id
                name
                s3location
                StructuralSystem{ 
                    s3location
                }
            }
        }`;

        if (structuralSystemS3Location || buildingS3Location) {
            return new Promise((resolve, reject) => {
                resolve({
                    data: {
                        Building: [
                            {
                                s3location: buildingS3Location,
                                StructuralSystem: {
                                    s3location: structuralSystemS3Location
                                }
                            }
                        ]
                    }
                });
            });
        } else {
            return axios
                .post(
                    url,
                    {
                        query,
                        variables
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    }
                )
                .then((response) => response.data);
        }
    };

    const getSceneTwin = (accessToken, sceneId) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`https://arche.thetwinmaster.com/api/v1/twin/world/${sceneId}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    const twinRes = response.data;
                    const twinImages = twinRes.images;
                    if (twinImages && twinImages.length > 0) {
                        resolve({
                            image: twinImages[0].s3_url,
                            name: twinRes.name
                        });
                    } else {
                        resolve({
                            image: null,
                            name: null
                        });
                    }
                })
                .catch((err) => {
                    console.error('Failed to retrieve twin image');
                    resolve({
                        image: null,
                        name: null
                    });
                });
        });
    };

    const getBuildingUriGlb = (buildingId, buildingUriGlb, buildingUriThumbnail) => {
        // console.log(`callAPI with ${uri_glb}...`);
        const parseS3Url = require('parse-aws-s3-url');
        const parts = parseS3Url(buildingUriGlb);
        const thumbnailParts =
            buildingUriThumbnail && buildingUriThumbnail !== ''
                ? parseS3Url(buildingUriThumbnail)
                : {
                      Bucket: '',
                      Key: ''
                  };

        //=> parts = {Bucket: 'my-bucket', Key: 'path/to/my/file.png'}
        const bucket = parts.Bucket;
        const key = parts.Key;
        const thumbnailBucket = thumbnailParts.Bucket;
        const thumbnailKey = thumbnailParts.Key;

        return fetch(
            `https://arche.thetwinmaster.com/twinworld/getGlbModel?bucket=${bucket}&s3_key=${key}&thumbnail_bucket=${thumbnailBucket}&s3_thumbnail_key=${thumbnailKey}`
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    // console.log(`Success: calling download_glb API with result ${JSON.stringify(result, null, 4)}`);
                    // console.log(`Result: ${JSON.stringify(result, null, 4)}`);
                    const glb_path = result.glb_path.replace('./utils', 'https://arche.thetwinmaster.com/community/twinworld/utils');
                    const thumbnail_path =
                        result.thumbnail_path && result.thumbnail_path !== ''
                            ? result.thumbnail_path.replace('./utils', 'https://arche.thetwinmaster.com/community/twinworld/utils')
                            : '';
                    // console.log(`GLB Path: ${JSON.stringify(glb_path)}`);
                    return {
                        glb: glb_path,
                        thumbnail: thumbnail_path
                    };
                },
                (error) => {
                    console.log(`Error: calling download_glb API with message ${error}`);
                }
            );
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    React.useEffect(() => {
        // const twinupCookie = Cookies.get('arche-twinup');
        const cookieData = JSON.parse(twinupCookie.replace(/\\054/g, ',').replace(/\\/g, '').replace(/^\"/, '').replace(/\"$/, ''));
        // document.querySelector('twinworld-app').shadowRoot.querySelector('.loaderbg').style.display = 'block';

        const sceneUrl = new URLSearchParams(window.location.search);
        const searchSceneId =
            window.location.pathname.indexOf('twinworld') >= 0 ? window.location.pathname.split('/').splice(-1)[0] : undefined;
        if (searchSceneId) {
            document.querySelector('twinworld-app').shadowRoot.querySelector('#cesiumContainer div').remove();
            if (document.querySelector('twinworld-app').shadowRoot.querySelector('#leftToolbar')) {
                document.querySelector('twinworld-app').shadowRoot.querySelector('#leftToolbar').remove();
            }
            if (document.querySelector('twinworld-app').shadowRoot.querySelector('#root header')) {
                document.querySelector('twinworld-app').shadowRoot.querySelector('#root header').remove();
            }
            if (document.querySelector('twinworld-app').shadowRoot.querySelector('#twoDimThreeDimSwitch')) {
                document.querySelector('twinworld-app').shadowRoot.querySelector('#twoDimThreeDimSwitch').remove();
            }
            if (
                document.querySelector('twinworld-app').shadowRoot.querySelector('.simplebar-wrapper') &&
                document.querySelector('twinworld-app').shadowRoot.querySelector('.simplebar-wrapper').parentNode.parentNode.parentNode
                    .parentNode
            ) {
                document
                    .querySelector('twinworld-app')
                    .shadowRoot.querySelector('.simplebar-wrapper').parentNode.parentNode.parentNode.parentNode.style.display = 'none';
            }
        }
        setTimeout(() => {
            fetch(
                searchSceneId
                    ? `https://arche.thetwinmaster.com/twinworld/getSceneById/${searchSceneId}`
                    : 'https://arche.thetwinmaster.com/twinworld/listAllScenesForUser',
                {
                    credentials: 'include'
                }
            )
                .then((res) => res.json())
                .then((resJson) => {
                    const allScenes = resJson;
                    setSceneList(allScenes);
                    setSceneItemsDom(sceneItems(allScenes));
                    window.twinWorldSceneList = allScenes;
                    const buildingGlbMap = {};
                    if (searchSceneId) {
                        const sceneArr = allScenes.filter((scene) => scene.scene_id === searchSceneId);
                        const scene = sceneArr.length > 0 ? sceneArr[0] : null;
                        if (scene) {
                            if (scene.BuildingScene) {
                                document.querySelector('twinworld-app').shadowRoot.querySelector('main').innerHTML =
                                    '<h2 style="color: #fff">Loading 3d scene...</h2>';
                                const buildingSceneParts = scene.BuildingScene.split(',');
                                const buildings3Location = buildingSceneParts.length > 1 ? buildingSceneParts[1] : null;
                                if (buildings3Location) {
                                    getBuildingUriGlb(scene.buildingid, buildings3Location, null).then((buildingGlbUri) => {
                                        buildingGlbMap[scene.buildingid] = buildingGlbUri.glb;
                                        scene.local_glb_uri = buildingGlbUri.glb;
                                        setTimeout(() => {
                                            loadBuildingScene(scene);
                                        }, 100);
                                    });
                                }
                            } else {
                                buildingDetails(
                                    cookieData.access_token,
                                    scene.buildingid,
                                    scene.buildingS3Location,
                                    scene.structuralSystemS3Location
                                ).then((buildingData) => {
                                    const buildingS3Location = buildingData.data.Building[0].s3location;
                                    const substring = ',';
                                    const index = buildingS3Location.indexOf(substring);
                                    if (buildingS3Location !== '') {
                                        const formattedBuildingS3Loc =
                                            index !== -1 ? buildingS3Location.substring(0, index) : buildingS3Location;
                                        const formattedThumbnailLoc =
                                            index !== -1 ? buildingS3Location.substring(index + 1, buildingS3Location.length) : '';
                                        const leanModelS3Location =
                                            buildingData.data.Building[0].StructuralSystem &&
                                            buildingData.data.Building[0].StructuralSystem.s3location
                                                ? buildingData.data.Building[0].StructuralSystem.s3location
                                                : null;
                                        // const finalBuildingS3Loc = leanModelS3Location ? leanModelS3Location : formattedBuildingS3Loc;
                                        getBuildingUriGlb(scene.buildingid, formattedBuildingS3Loc, formattedThumbnailLoc).then(
                                            (buildingGlbUri) => {
                                                buildingGlbMap[scene.buildingid] = buildingGlbUri.glb;
                                                scene.local_glb_uri = buildingGlbUri.glb;
                                                scene.local_thumbnail_uri = buildingGlbUri.thumbnail;
                                                viewer.clock.onTick.removeEventListener(window.applyGlobeSpin);
                                                setTimeout(() => {
                                                    loadScene(scene);
                                                }, 100);
                                            }
                                        );
                                    }
                                });
                            }
                        }
                    } else {
                        allScenes.forEach((scene) => {
                            if (!scene.BuildingScene) {
                                buildingDetails(cookieData.access_token, scene.buildingid).then((buildingData) => {
                                    const buildingS3Location = buildingData.data.Building[0].s3location;
                                    const substring = ',';
                                    const index = buildingS3Location.indexOf(substring);
                                    if (buildingS3Location !== '') {
                                        const formattedBuildingS3Loc =
                                            index !== -1 ? buildingS3Location.substring(0, index) : buildingS3Location;
                                        const formattedThumbnailLoc =
                                            index !== -1 ? buildingS3Location.substring(index + 1, buildingS3Location.length) : '';
                                        const leanModelS3Location =
                                            buildingData.data.Building[0].StructuralSystem &&
                                            buildingData.data.Building[0].StructuralSystem.s3location
                                                ? buildingData.data.Building[0].StructuralSystem.s3location
                                                : null;
                                        // const finalBuildingS3Loc = leanModelS3Location ? leanModelS3Location : formattedBuildingS3Loc;
                                        if (buildingGlbMap[scene.buildingid] && buildingGlbMap[scene.buildingid].local_glb_uri) {
                                            scene.local_glb_uri = buildingGlbMap[scene.buildingid].local_glb_uri;
                                            scene.local_thumbnail_uri = buildingGlbMap[scene.buildingid].local_thumbnail_uri;
                                            if (scene.scene_id === searchSceneId) {
                                                loadScene(scene);
                                            } else {
                                                setSceneList(allScenes);
                                                setSceneItemsDom(sceneItems(allScenes));
                                                window.twinWorldSceneList = allScenes;
                                            }
                                        } else {
                                            if (!buildingGlbMap[scene.buildingid]) {
                                                buildingGlbMap[scene.buildingid] = '';
                                            }
                                            fetch(
                                                `https://arche.thetwinmaster.com/twinworld/getGeolocation?latitude=${scene.latitude}&longitude=${scene.longitude}`
                                            )
                                                .then((res) => res.text())
                                                .then((resText) => {
                                                    const placeName = resText;
                                                    scene.placeName = placeName;
                                                    getBuildingUriGlb(scene.buildingid, formattedBuildingS3Loc, formattedThumbnailLoc).then(
                                                        (buildingGlbUri) => {
                                                            buildingGlbMap[scene.buildingid] = buildingGlbUri.glb;
                                                            scene.local_glb_uri = buildingGlbUri.glb;
                                                            scene.local_thumbnail_uri = buildingGlbUri.thumbnail;
                                                            getSceneTwin(cookieData.access_token, scene.scene_id).then((twin) => {
                                                                if (twin && twin.image) {
                                                                    scene.local_thumbnail_uri = twin.image;
                                                                }
                                                                if (twin && twin.name) {
                                                                    scene.name = twin.name;
                                                                }
                                                                if (scene.scene_id === searchSceneId) {
                                                                    loadScene(scene);
                                                                } else {
                                                                    setSceneList(allScenes);
                                                                    setSceneItemsDom(sceneItems(allScenes));
                                                                    window.twinWorldSceneList = allScenes;
                                                                }
                                                            });
                                                        }
                                                    );
                                                });
                                        }
                                    }
                                });
                            }
                        });
                    }
                });
        }, 1000);
        // eslint-disable-next-line
    }, []);

    return (
        <Box sx={{ maxWidth: 1200, flexGrow: 1, zIndex: 1001 }}>
            <AliceCarousel
                mouseTracking
                // items={sceneItems(sceneList)}
                items={sceneItemsDom}
                responsive={responsive}
                controlsStrategy="alternate"
                disableDotsControls="true"
                renderPrevButton={() => {
                    return (
                        <p
                            style={{ position: 'absolute', left: '-3px', top: '23px', transform: 'scale(0.8) translateY(-10px)' }}
                            className="p-4 absolute left-0 top-0"
                        >
                            <img alt="left" src="https://twinworld-component.s3.amazonaws.com/build/utils/lib/LeftArrow.png" />
                        </p>
                    );
                }}
                renderNextButton={() => {
                    return (
                        <p style={{ position: 'absolute', right: '10px', top: '0' }} className="p-4 absolute right-0 top-0">
                            {/* <img alt="right" src="https://twinworld-component.s3.amazonaws.com/build/utils/lib/RightArrow.png" /> */}
                        </p>
                    );
                }}
            />
        </Box>
    );
}

export default Scenes;
