import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Scenes from './Scenes';
import Tasks from './Tasks';

const options = ['Tasks', 'Scenes'];

export default function SceneTaskExplorer(props) {
    const { modelUploaded, modelUploadingInProcess } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    React.useEffect(() => {
        window.switchSceneTask = function (sceneOrTask) {
            if (sceneOrTask === 'scene') {
                setSelectedIndex(0);
            } else {
                setSelectedIndex(1);
            }
        };
        if (window.location.pathname.indexOf('twinworld') >= 0) {
            setSelectedIndex(1);
        }
    }, []);

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <List
                component="nav"
                aria-label="Device settings"
                sx={{
                    bgcolor: '#363c4f',
                    height: '25px',
                    zIndex: 1000,
                    marginTop: '-5px',
                    position: 'absolute',
                    left: '7px',
                    zIndex: 1001
                }}
            >
                <ListItem
                    button
                    sx={{ padding: 0, maxHeight: '20px', margin: 0 }}
                    id="lock-button"
                    aria-haspopup="listbox"
                    aria-controls="lock-menu"
                    aria-label="when device is locked"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickListItem}
                >
                    <ListItemText
                        primary={
                            <div>
                                <span style={{ fontSize: '13px', color: '#fff' }}>{options[selectedIndex]} </span>
                                <img
                                    style={{ width: '10px', transform: 'translateY(2.5px)' }}
                                    alt="sceneTask"
                                    src="https://twinworld-component.s3.amazonaws.com/build/utils/lib/sceneSwitcher2.png"
                                />
                            </div>
                        }
                    />
                </ListItem>
            </List>
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox'
                }}
            >
                {options.map((option, index) => (
                    <MenuItem key={option} selected={index === selectedIndex} onClick={(event) => handleMenuItemClick(event, index)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
            {selectedIndex === 1 && <Scenes />}
            {selectedIndex === 0 && <Tasks modelUploaded={modelUploaded} modelUploadingInProcess={modelUploadingInProcess} />}

            <div
                id="snapshots"
                style={{
                    width: '40%',
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    height: '20px',
                    position: 'absolute',
                    transform: 'translate3d(500px, -50px, 10px)',
                    zIndex: '1000'
                }}
            ></div>
        </div>
    );
}
