import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { PieChart } from '@mui/x-charts/PieChart';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Icon from '@ant-design/icons';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';

import { ReactComponent as SunIconSvg } from '../../../icons/WeatherTool/sunShine.svg';
import { ReactComponent as WindGaugeIconSvg } from '../../../icons/WeatherTool/windGauge.svg';
import { ReactComponent as WindIconSvg } from '../../../icons/WeatherTool/wind.svg';
import { ReactComponent as PrecipitationIconSvg } from '../../../icons/WeatherTool/precipitation.svg';
import { ReactComponent as FeelsLikeIconSvg } from '../../../icons/WeatherTool/feelsLike.svg';
import { ReactComponent as HumidityIconSvg } from '../../../icons/WeatherTool/humidity.svg';
import { ReactComponent as VisibilityIconSvg } from '../../../icons/WeatherTool/visibility.svg';
import { ReactComponent as PressureIconSvg } from '../../../icons/WeatherTool/pressure.svg';

const SvgIcon = (svgComponent, props) => <Icon component={svgComponent} {...props} />;
const icons = {
    SunIcon: (props) => SvgIcon(SunIconSvg, props),
    WindIcon: (props) => SvgIcon(WindIconSvg, props),
    WindGaugeIcon: (props) => SvgIcon(WindGaugeIconSvg, props),
    PrecipitationIcon: (props) => SvgIcon(PrecipitationIconSvg, props),
    FeelsLikeIcon: (props) => SvgIcon(FeelsLikeIconSvg, props),
    HumidityIcon: (props) => SvgIcon(HumidityIconSvg, props),
    VisibilityIcon: (props) => SvgIcon(VisibilityIconSvg, props),
    PressureIcon: (props) => SvgIcon(PressureIconSvg, props)
};

const autocompleteService = { current: null };

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            style={{ padding: '1px' }}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3, padding: '1px' }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.element,
    value: PropTypes.number,
    index: PropTypes.number
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    };
}

const BuildingMetricsTool = ({ latitude, longitude }) => {
    const theme = useTheme();
    const [checkedBuildingTypes, setCheckedBuildingTypes] = React.useState(new Set());
    const [actionValue, setActionValue] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    const [hoverOnGauge, setHoverOnGauge] = React.useState(false);
    const [loadingBuildingMetrics, setLoadingBuildingMetrics] = React.useState(false);
    const [lastBuildingId, setLastBuildingId] = React.useState();

    const [weatherData, setWeatherData] = React.useState({
        placeName: '',
        temperature: 67,
        uvIndex: 'Moderate',
        uvProtectionTill: '',
        sunset: '6:41 PM',
        sunrise: '5:27 AM',
        windSpeed: '3',
        precipitation: '0',
        rain: '0',
        feelsLike: '',
        humidity: '',
        dewPoint: '',
        visibility: '',
        pressure: ''
    });

    const [metricsData, setMetricsData] = React.useState([]);

    const [multiMetricsData, setMultiMetricsData] = React.useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const debounce = (func, wait) => {
        let timeout;

        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };

            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    };

    React.useEffect(() => {
        if (window.activeOption === 'measurePerf' && window.lastPickedBuildingId && window.lastPickedBuildingId != lastBuildingId) {
            setLastBuildingId(window.lastPickedBuildingId);

            const allFeatures = window.allPickedAssets.map(
                (pickedAsset) =>
                    new Promise((resolve, reject) => {
                        fetch(`https://arche.thetwinmaster.com/twinworld/getBuildingPerformance?buildingid=${pickedAsset.featureId}`, {
                            method: 'GET',
                            credentials: 'include'
                        })
                            .then((res) => res.json())
                            .then((resJson) => {
                                if (resJson.ErrorCode) {
                                    resolve({ CO2: '', site_eui: '', source_eui: '' });
                                } else {
                                    resolve(resJson);
                                }
                            })
                            .catch((err) => {
                                console.error('Failed to retrieve data for ', pickedAsset.featureId);
                                resolve({ CO2: '', site_eui: '', source_eui: '' });
                            });
                    })
            );
            Promise.all(allFeatures).then((allResponses) => {
                const allResponseParsed = allResponses.map((resJson) => {
                    console.log('Performance Data ::', resJson);
                    const parsedPerformanceMetrics = Object.keys(resJson).map((key) => {
                        return {
                            value: resJson[key],
                            label: key
                        };
                    });
                    return parsedPerformanceMetrics;
                });
                setMultiMetricsData(allResponseParsed);
            });
            fetch(`https://arche.thetwinmaster.com/twinworld/getBuildingPerformance?buildingid=${window.lastPickedBuildingId}`, {
                method: 'GET',
                credentials: 'include'
            })
                .then((res) => res.json())
                .then((resJson) => {
                    console.log('Performance Data ::', resJson);
                    const parsedPerformanceMetrics = Object.keys(resJson).map((key) => {
                        return {
                            value: resJson[key],
                            label: key
                        };
                    });
                    console.log('Parsed perf metrics ', parsedPerformanceMetrics);
                    setMetricsData(parsedPerformanceMetrics);
                    setTimeout(() => {
                        document
                            .querySelectorAll('style')
                            .forEach((style) => document.querySelector('twinworld-app').shadowRoot.append(style.cloneNode(true)));
                    }, 500);
                });
        }
    });

    React.useEffect(() => {
        document
            .querySelectorAll('style')
            .forEach((style) => document.querySelector('twinworld-app').shadowRoot.append(style.cloneNode(true)));
    }, []);

    return (
        <React.Fragment>
            <Box sx={{ padding: '15px' }}>
                <Fab
                    aria-label="close"
                    onClick={() => {
                        document.querySelector('twinworld-app').shadowRoot.querySelector('#buildingMetricsVisualizer').click();
                    }}
                    color="gray"
                    className="legendClose"
                >
                    <CloseIcon sx={{ color: '#fff !important', transform: 'scale(2) translateY(5.2px)' }} />
                </Fab>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="notification/weather tabs">
                        {/* <Tab
                            sx={{
                                color: '#fff',
                                fontFamily: 'Suisse Intl',
                                fontSize: '16px',
                                textTransform: 'uppercase',
                                fontWeight: 'bold'
                            }}
                            label="Notification"
                            {...a11yProps(0)}
                        /> */}
                        <Tab
                            sx={{
                                color: '#fff',
                                fontFamily: 'Suisse Intl',
                                fontSize: '16px',
                                // textTransform: 'uppercase',
                                fontWeight: 'bold'
                            }}
                            label="Building Performance"
                            {...a11yProps(1)}
                        />
                    </Tabs>
                </Box>
                <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
                    <TabPanel value={value} index={0} dir={theme.direction} style={{ padding: '0', overflowX: 'hidden' }}>
                        {loadingBuildingMetrics && (
                            <Stack
                                spacing={0}
                                sx={{
                                    height: '300px',
                                    width: '90vw',
                                    margin: 'auto',
                                    display: 'grid',
                                    placeContent: 'center',
                                    textAlign: 'center'
                                }}
                            >
                                <CircularProgress />
                            </Stack>
                        )}
                        {!loadingBuildingMetrics && (
                            <Stack spacing={0} sx={{ width: '100%' }}>
                                <Stack
                                    className="buildingMetricsWidgets"
                                    spacing={2}
                                    sx={{ width: '100%', marginLeft: '8px', color: '#fff' }}
                                >
                                    <Grid container spacing={2}>
                                        {multiMetricsData.map((metricsData, index) => (
                                            <Grid key={index * 1000} item xs={4}>
                                                <PieChart
                                                    series={[
                                                        {
                                                            data: metricsData,
                                                            innerRadius: 45,
                                                            outerRadius: 100,
                                                            paddingAngle: 0.5,
                                                            cornerRadius: 0.5,
                                                            startAngle: -180,
                                                            endAngle: 180
                                                        }
                                                    ]}
                                                    height={210}
                                                    width={300}
                                                    margin={{ right: 5 }}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Grid container spacing={0}>
                                        <PieChart
                                            series={[
                                                {
                                                    data: metricsData,
                                                    innerRadius: 45,
                                                    outerRadius: 100,
                                                    paddingAngle: 0.5,
                                                    cornerRadius: 0.5,
                                                    startAngle: -180,
                                                    endAngle: 180
                                                }
                                            ]}
                                            height={210}
                                            width={300}
                                            margin={{ right: 5 }}
                                        />
                                    </Grid>
                                </Stack>
                            </Stack>
                        )}
                    </TabPanel>
                </SwipeableViews>
            </Box>
        </React.Fragment>
    );
};

BuildingMetricsTool.propTypes = {
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired
};
export default BuildingMetricsTool;
